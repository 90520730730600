import { EStatus } from '@adtech/ui';
import { TFunction } from 'react-i18next';
import { IUserPerm, TEntities, UserAccountStatus } from './types';

const getUserAccountStatus = (
    userAccountStatus: UserAccountStatus,
    t: TFunction,
): { title: string; statusIcon: keyof typeof EStatus } => {
    switch (userAccountStatus) {
        case UserAccountStatus.CREATED:
            return {
                title: t(`UserStatus.${UserAccountStatus.CREATED}`),
                statusIcon: EStatus.draft,
            };
        case UserAccountStatus.ACTIVATED:
            return {
                title: t(`UserStatus.${UserAccountStatus.ACTIVATED}`),
                statusIcon: EStatus.end,
            };
        case UserAccountStatus.READY:
            return {
                title: t(`UserStatus.${UserAccountStatus.READY}`),
                statusIcon: EStatus.active,
            };
        case UserAccountStatus.PERMREQ:
            return {
                title: t(`UserStatus.${UserAccountStatus.PERMREQ}`),
                statusIcon: EStatus.plan,
            };
        default:
            return {
                title: t(`UserStatus.${UserAccountStatus.BLOCKED}`),
                statusIcon: EStatus.decline,
            };
    }
};

export const transformPermissionData = (stateUserData: IUserPerm[]) => {
    const transformedData = stateUserData.reduce((acc, val) => {
        acc[val.role] = val.permission;
        return acc;
    }, {} as { [key: string]: TEntities[] | null });
    return {
        roles: Object.keys(transformedData),
    };
};

export default getUserAccountStatus;
