import ErrorBoundary from 'app/ErrorBoundary/ui/ErrorBoundaty';
import { StoreProvider } from 'app/providers/StoreProvider';
import React from 'react';
import { render } from 'react-dom';

import { BrowserRouter } from 'react-router-dom';

import App from './app/App';

render(
    <BrowserRouter>
        <StoreProvider>
            <ErrorBoundary>
                <App />
            </ErrorBoundary>
        </StoreProvider>
    </BrowserRouter>,
    document.getElementById('root'),
);
