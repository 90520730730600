import { api } from 'shared/config/appConfig/api';
import { IBatchEntityList, IBatchEntityListArgs } from './types';

const baseUrl = 'lk/v2/batch';

export const massLoadApi = api.injectEndpoints({
    endpoints: (builder) => ({
        loadBatch: builder.mutation({
            query: (arg: FormData) => ({
                url: `${baseUrl}/registration`,
                method: 'POST',
                body: arg,
            }),
        }),
        getBatchList: builder.mutation<IBatchEntityList, Omit<IBatchEntityListArgs, 'url' | 'processed' | 'total'>>({
            query: (arg) => ({
                url: `${baseUrl}/list`,
                method: 'POST',
                body: arg,
            }),
        }),
    }),
});

export const { useLoadBatchMutation, useGetBatchListMutation } = massLoadApi;
