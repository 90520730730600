// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SBA-menu-sider,
.SBA-menu {
  z-index: 1;
  background: transparent;
}
.SBA-menu-sider .SBA-menu-inline,
.SBA-menu-sider .SBA-menu-vertical,
.SBA-menu-sider .SBA-menu-vertical-left {
  border: none;
}
.SBA-menu.SBA-menu-inline-collapsed > .SBA-menu-item .SBA-menu-item-icon + span {
  opacity: 1;
}
.SBA-menu .SBA-menu-item .SBA-menu-title-content {
  margin: 0;
  color: var(--color-snow, #fff) !important;
  text-align: center;
  white-space: break-spaces;
}
.SBA-menu .SBA-menu-item.SBA-menu-item-active {
  background-color: var(--color-blue-carbon-rgb-25-opacity);
}
.SBA-menu .SBA-menu-item.SBA-menu-item-selected {
  background-color: transparent;
}
.SBA-menu .SBA-menu-item.SBA-menu-item-selected:after {
  background-color: var(--color-arctic-ads);
}
.SBA-menu .SBA-menu-item.mass-load svg {
  margin: 0;
  font-size: 16px;
  line-height: 40px;
  width: 24px;
  height: 24px;
  margin-bottom: 8px;
}
.SBA-menu .SBA-menu-item.delete-entitie svg {
  margin: 0;
  font-size: 16px;
  line-height: 40px;
  width: 24px;
  height: 24px;
  margin-bottom: 8px;
}
.SBA-menu .SBA-menu-item.delete-entitie svg path {
  fill: white;
}
`, "",{"version":3,"sources":["webpack://./src/widgets/Sider/Sider.less"],"names":[],"mappings":"AASA;;EAEI,UAAA;EACA,uBAAA;AARJ;AAWA;;;EAIQ,YAAA;AAVR;AAcA;EACI,UAAA;AAZJ;AAeA;EAGY,SAAA;EACA,yCAAA;EACA,kBAAA;EACA,yBAAA;AAfZ;AAkBQ;EACI,yDAAA;AAhBZ;AAmBQ;EACI,6BAAA;AAjBZ;AAmBY;EACI,yCAAA;AAjBhB;AAqBQ;EA/CJ,SAAA;EACA,eAAA;EACA,iBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;AA6BJ;AAmBQ;EArDJ,SAAA;EACA,eAAA;EACA,iBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;AAqCJ;AAWQ;EAIY,WAAA;AAZpB","sourcesContent":[".icon-mixin() {\n    margin: 0;\n    font-size: 16px;\n    line-height: 40px;\n    width: 24px;\n    height: 24px;\n    margin-bottom: 8px;\n}\n\n.SBA-menu-sider,\n.SBA-menu {\n    z-index: 1;\n    background: transparent;\n}\n\n.SBA-menu-sider {\n    .SBA-menu-inline,\n    .SBA-menu-vertical,\n    .SBA-menu-vertical-left {\n        border: none;\n    }\n}\n\n.SBA-menu.SBA-menu-inline-collapsed > .SBA-menu-item .SBA-menu-item-icon + span {\n    opacity: 1;\n}\n\n.SBA-menu {\n    .SBA-menu-item {\n        .SBA-menu-title-content {\n            margin: 0;\n            color: var(--color-snow, #fff) !important;\n            text-align: center;\n            white-space: break-spaces;\n        }\n\n        &.SBA-menu-item-active {\n            background-color: var(--color-blue-carbon-rgb-25-opacity);\n        }\n\n        &.SBA-menu-item-selected {\n            background-color: transparent;\n\n            &:after {\n                background-color: var(--color-arctic-ads);\n            }\n        }\n\n        &.mass-load {\n            svg {\n                .icon-mixin();\n            }\n        }\n\n        &.delete-entitie {\n            svg {\n                .icon-mixin();\n                path {\n                    fill: white;\n                }\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
