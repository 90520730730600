import { Card } from '@adtech/ui';
import { useAppSelector } from 'app/providers/StoreProvider';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import IconBot from 'shared/assets/IconBot.svg';
import IconComics from 'shared/assets/IconComics.svg';
import IconDocuments from 'shared/assets/IconDocuments.svg';
import IconFAS from 'shared/assets/IconFAS.svg';
import IconORD from 'shared/assets/IconORD.svg';
import IconVideo from 'shared/assets/IconVideo.svg';
import './KnowledgeBase.less';

const KnowledgeBase: FC = () => {
    const tgLink = useAppSelector((selector) => selector.appSlice.telegramInviteLink);
    const cards = [
        {
            title: 'Видеоматериалы',
            icon: <IconVideo />,
            href: 'https://ord-lab.ru/webinars',
        },
        {
            title: 'Иллюстрации',
            icon: <IconComics />,
            href: 'https://ord-lab.ru/comics',
        },
        {
            title: 'Документация',
            icon: <IconDocuments />,
            href: 'https://ord-lab.ru/documentation',
        },
        {
            title: 'Разъяснения ОРД',
            icon: <IconORD />,
            href: 'https://ord-lab.ru/faq-ord',
        },
        {
            title: 'Разъяснения ФАС',
            icon: <IconFAS />,
            href: 'https://ord-lab.ru/faq-fas',
        },
        {
            title: 'Telegram-Bot',
            icon: <IconBot />,
            href: tgLink || `https://t.me/sberads_ord_bot?start=${btoa('not_auth')}`,
        },
    ];
    return (
        <div className="knowledge-base-cards" id="knowledge-base-card">
            {cards.map((item) => (
                <Link to={item.href} target="_blank">
                    <Card
                        icon={item.icon}
                        className="knowledge-base-card"
                        id="knowledge-base-card"
                        title={item.title}
                    />
                </Link>
            ))}
        </div>
    );
};

export default KnowledgeBase;
