import { Modal } from '@adtech/ui';
import { Button } from 'antd';
import { useReadMessageMutation } from 'pages/MainPage/model/creativeStatApi';
import { ErirMessagesStatuses, IErirMessages } from 'pages/MainPage/model/creativesStatTypes';
import React, { FC, useState } from 'react';
import { useLocation } from 'react-router-dom';

interface IMessageModalProps {
    title: string;
    message: string;
    messageId: string;
    status: ErirMessagesStatuses;
    setMessages: React.Dispatch<React.SetStateAction<IErirMessages[]>>;
}

const MessageModal: FC<IMessageModalProps> = ({ title, message, messageId, status, setMessages }) => {
    const [open, setOpen] = useState(false);
    const [readMessage] = useReadMessageMutation();
    const location = useLocation();
    const mainPage = location.pathname === '/';
    return (
        <>
            <Button type="link" onClick={() => setOpen(true)} style={{ paddingLeft: '0px' }}>
                {title}
            </Button>
            <Modal
                isOpened={open}
                title={title}
                closeOnClickOutside
                closeOnEsc
                destroyOnClose
                okText={status === ErirMessagesStatuses.NEW ? 'Отметить прочитанным' : undefined}
                cancelText={"Закрыть"}
                onOk={() => {
                    readMessage({ messageId })
                        .unwrap()
                        .then(() => {
                            setMessages((prev) => {
                                const prevCopy = [...prev];
                                const readedMessageIndex = prevCopy.findIndex((item) => item.messageId === messageId);
                                prevCopy[readedMessageIndex] = {
                                    ...prevCopy[readedMessageIndex],
                                    status: ErirMessagesStatuses.READ,
                                };
                                if (mainPage) {
                                    return prevCopy.filter((item) => item.status !== ErirMessagesStatuses.READ);
                                }
                                return prevCopy;
                            });
                            setOpen(false);
                        });
                }}
                onClose={() => setOpen(false)}
            >
                {message}
            </Modal>
        </>
    );
};

export default MessageModal;
