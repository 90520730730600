export interface IFeatureFlag {
    orgLinkedEntities: boolean;
    conLinkedCreatives: boolean;
    conLinkedAdditionalAgreement: boolean;
    creLinkedEntities: boolean;
    deleteEntities: boolean;
    batchLoad: boolean;
    platforms: boolean;
    creativesTA: boolean;
}

export const featureFlagController: IFeatureFlag = {
    orgLinkedEntities: true,
    conLinkedCreatives: true,
    conLinkedAdditionalAgreement: false,
    creLinkedEntities: true,
    deleteEntities: true,
    batchLoad: true,
    platforms: true,
    creativesTA: true,
};

export const getFlagController = () => {
    if (window.location.origin.includes('ord-lab.dev') || window.location.origin.includes('localhost')) {
        return JSON.parse(localStorage.getItem('featureFlagController') as string) || featureFlagController;
    }
    return featureFlagController;
};
