import { lazy } from 'react';
import { useLoadBatchMutation, useGetBatchListMutation } from './model/massLoadApi';
import { massLoadSlice, resetDefaultItems, setChoosedCols, setColumns, setDefaultItems } from './model/massLoadslice';
import { allowedTypes, BatchStatuses } from './model/types';
import BatchStatusView from './ui/BatchStatusView';
import type { IMassLoadSlice, IBatchEntity, IBatchEntityListArgs } from './model/types';

const MassLoadRouter = lazy(() => import('./routes/MassLoadRoute'))

export {
    MassLoadRouter,
    BatchStatusView,
    allowedTypes,
    massLoadSlice,
    resetDefaultItems,
    setChoosedCols,
    setColumns,
    setDefaultItems,
    useLoadBatchMutation,
    useGetBatchListMutation,
    BatchStatuses,
    IMassLoadSlice,
    IBatchEntity,
    IBatchEntityListArgs,
};
