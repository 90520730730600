import { TTableSettingsListItemProps } from '@adtech/ui';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IUserEntityPerm, IUserPerm, IUserSlice } from './types';

const initialState: IUserSlice = {
    role: [],
    columns: undefined,
    choosedCols: undefined,
    defualtItems: [],
    userDetailPermission: null,
    permissions: null,
};

export const userSlice = createSlice({
    name: 'userSlice',
    initialState,
    reducers: {
        setColumns: (state, action) => {
            state.columns = action.payload;
            state.choosedCols = action.payload;
        },
        setDefaultItems: (state, action: PayloadAction<TTableSettingsListItemProps[]>) => {
            state.defualtItems = action.payload;
        },
        resetDefaultItems: (state) => {
            state.defualtItems = state.defualtItems.map((item) => {
                if (item.checked === true && item.disabled !== true) {
                    return { ...item, checked: false };
                }
                return item;
            });
        },
        setChoosedCols: (state, action: PayloadAction<TTableSettingsListItemProps[]>) => {
            const colsToSet = action.payload.filter((item) => item.checked === true);
            const selectedCols: any = [];
            colsToSet.forEach((item) => {
                selectedCols.push(state.columns?.find((col) => col.key === item.id));
            });
            state.choosedCols = selectedCols;
        },
        setUserData: (state, action: PayloadAction<IUserPerm[]>) => {
            state.role = action.payload;
        },
        setUserDetailPermission: (state, action: PayloadAction<IUserEntityPerm[]>) => {
            state.userDetailPermission = action.payload;
        },
        addUserDetailPermission: (state, action: PayloadAction<IUserEntityPerm>) => {
            if (state.userDetailPermission === null) {
                state.userDetailPermission = [action.payload];
            } else {
                state.userDetailPermission?.push(action.payload);
            }
        },
        deleteUserDetailPermission: (state, action) => {
            if (state.userDetailPermission !== null) {
                state.userDetailPermission = state.userDetailPermission?.filter((perm) => perm.id !== action.payload);
                if (state.userDetailPermission?.length === 0) {
                    state.userDetailPermission = null;
                }
            }
        },
    },
});

export const {
    setUserData,
    setChoosedCols,
    setColumns,
    setDefaultItems,
    resetDefaultItems,
    setUserDetailPermission,
    addUserDetailPermission,
    deleteUserDetailPermission,
} = userSlice.actions;
