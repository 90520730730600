import { TTableSettingsListItemProps } from '@adtech/ui';
import { ColumnsType } from 'antd/lib/table';
import React, { ReactNode } from 'react';
import { IEntityInfo, IStatus, OrdStatus } from 'shared/lib/types';

export enum OrgnisationType {
    FL = 'fl',
    UL = 'ul',
    IP = 'ip',
    FFL = 'ffl',
    FUL = 'ful',
}
export interface IOrganizationPlatform {
    externalId?: string | null;
    isOwned?: boolean;
    name?: string | null;
    platformId?: string;
    type?: string | null;
    url?: string | null;
    tempId?: string;
    status?: OrdStatus;
}

export interface IOrganization {
    id?: string;
    name?: string | null;
    type?: OrgnisationType | null;
    oksmNumber?: string | null;
    alternativeInn?: string | null;
    epayNumber?: string | null;
    inn?: string | null;
    isOrs?: boolean | null;
    isRr?: boolean | null;
    mobilePhone?: string | null;
    platforms?: IOrganizationPlatform[];
    regNumber?: string | null;
    rsUrl?: string | null;
}

export interface IOrganisationEntity {
    entity?: IEntityInfo;
    orgnisation?: IOrganization;
}

export interface IOrganisationEntityResponse {
    data?: IOrganisationEntity[];
    meta: {
        limit: number;
        page: number;
        total: number;
    };
}

export interface IOrganisationPostArg {
    body: IOrganization;
}
export interface IOrganisationPutArg {
    id: string;
    body: IOrganization;
}

export interface ICreateEditResponse {
    data: IStatus;
}

// SearchParams
export interface IOrganizationsSearchParams {
    id?: Array<string> | null;
    name?: string | null;
    limit?: number;
    page?: number;
    status?: Array<string> | string | null;
    type?: Array<string> | string | null;
    inn?: string | null;
    erirRegDate?: string | null;
    created?: string | null;
    sorting?: Array<string> | null | undefined;
}

export interface IOrganisationEntityArg {
    body?: IOrganizationsSearchParams;
}

// Countries
export interface ICountriesList {
    id: string;
    label: string;
}
export interface ICountriesListResponse {
    data: ICountriesList[];
}
export interface ICountryItem {
    id?: string;
    label?: string;
}
export interface ICountrySearchResponse {
    data?: ICountryItem[];
}

// ColumnsType
export type TOrgCols = ColumnsType<IOrganisationEntity>;

export type TOrganizationBatchConfig = Record<
    keyof Omit<IOrganization, 'id' | 'platforms'>,
    { label: string; tooltip: ReactNode }
>;

// slice state type
export interface IORGSlice {
    columns?: ColumnsType<IOrganisationEntity>;
    defualtItems: TTableSettingsListItemProps[];
    choosedCols?: ColumnsType<IOrganisationEntity>;
    platforms: IOrganizationPlatform[];
    countriesMap?: { [key: string]: string };
    batchConfigLabelMap: TOrganizationBatchConfig;
}

export const batchConfigLabelMap: TOrganizationBatchConfig = {
    name: {
        label: 'Название организации (тип данных строковый)',
        tooltip: <div>Пример: ООО &quot;Ромашка&quot;</div>,
    },
    type: {
        label: 'Тип организации (тип данных строковый)',
        tooltip: (
            <div>
                Возможные варианты:
                <ul>
                    <li>Физическое лицо</li>
                    <li>Индивидуальный предприниматель</li>
                    <li>Юридическое лицо</li>
                    <li>Иностранное физическое лицо</li>
                    <li>Иностранное юридическое лицо</li>
                </ul>
            </div>
        ),
    },
    inn: {
        label: 'ИНН (тип данных строковый)',
        tooltip: <div>Пример: 424529020351</div>,
    },
    oksmNumber: {
        label: 'Страна (Номер ОКСМ) (тип данных числовой)',
        tooltip: <div>Пример: 643</div>,
    },
    alternativeInn: {
        label: 'Альтернативный ИНН (для иностранных представителей) (тип данных строковый)',
        tooltip: (
            <div>
                Резиденты: пустое
                <br />
                Нерезиденты: обязательное для физлиц в случае оператора рекламной системы
                <br />
                Обязательно для юрлиц в случае если не заполнено поле <strong>Регистрационный номер</strong>
            </div>
        ),
    },
    epayNumber: {
        label: 'Номер электронного платежа (тип данных строковый)',
        tooltip: (
            <div>
                Резиденты: пустое
                <br />
                Нерезиденты: обязательно для физлиц если не заполнено поле <strong>Телефон</strong>
            </div>
        ),
    },
    mobilePhone: {
        label: 'Номер телефона (тип данных строковый)',
        tooltip: (
            <div>
                Резиденты: пустое
                <br />
                Нерезиденты: обязательно для физлиц если не заполнено поле <strong>Номер электронного платеж</strong>
            </div>
        ),
    },
    regNumber: {
        label: 'Регистрационный номер (тип данных строковый)',
        tooltip: (
            <div>
                Резиденты: пустое
                <br />
                Нерезиденты: обязательное для юрлиц в случае если не заполнено поле<strong>Альтернативный ИНН</strong>
            </div>
        ),
    },
    isOrs: {
        label: 'Является ли организация оператором рекламной системы (тип данных строковый)',
        tooltip: (
            <div>
                Возможные варианты:
                <ul>
                    <li>Да</li>
                    <li>Нет</li>
                </ul>
            </div>
        ),
    },
    isRr: {
        label: 'Является ли организация рекламораспространителем (тип данных строковый)',
        tooltip: (
            <div>
                Возможные варианты:
                <ul>
                    <li>Да</li>
                    <li>Нет</li>
                </ul>
            </div>
        ),
    },
    rsUrl: {
        label: 'Сведения об ИС и программе ЭВМ (тип данных строковый)',
        tooltip: (
            <div>
                Заполняется, если организация является оператором рекламной системы
                <br />
                Пример: https://ord-lab.ru
            </div>
        ),
    },
};
