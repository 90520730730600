import { TTableSettingsListItemProps } from '@adtech/ui';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IContract, IContractsSlice, batchConfigLabelMap } from './types';

const initialState: IContractsSlice = {
    defualtItems: [],
    choosedCols: undefined,
    columns: undefined,
    batchConfigLabelMap,
    cobrend: {
        clients: [],
        mainData: undefined,
        cleintCashe: {},
    },
};

export const contractSlice = createSlice({
    name: 'contractSlice',
    initialState,
    reducers: {
        setColumns: (state, action) => {
            state.columns = action.payload;
            state.choosedCols = action.payload;
        },
        setDefaultItems: (state, action: PayloadAction<TTableSettingsListItemProps[]>) => {
            state.defualtItems = action.payload;
        },
        resetDefaultItems: (state) => {
            state.defualtItems = state.defualtItems.map((item) => {
                if (item.checked === true && item.disabled !== true) {
                    return { ...item, checked: false };
                }
                return item;
            });
        },
        setChoosedCols: (state, action: PayloadAction<TTableSettingsListItemProps[]>) => {
            const colsToSet = action.payload.filter((item) => item.checked === true);
            const selectedCols: any = [];
            colsToSet.forEach((item) => {
                selectedCols.push(state.columns?.find((col) => col.key === item.id));
            });
            state.choosedCols = selectedCols;
        },
        setCobrendMainData: (state, action: PayloadAction<Omit<IContract, 'clientId' | 'isVat' | 'amount'>>) => {
            state.cobrend.mainData = action.payload;
        },
        addCobrendMember: (state, action: PayloadAction<{ clientId: string; isVat: boolean; amount: number }>) => {
            state.cobrend.clients?.push({ ...action.payload, loadState: 'pending' });
        },
        deleteCobrendMember: (state, action: PayloadAction<{ id: string }>) => {
            state.cobrend.clients = state.cobrend.clients?.filter((client) => client.clientId !== action.payload.id);
        },
        cacheClient: (state, action) => {
            if (state.cobrend.cleintCashe[action.payload.value]) return;
            state.cobrend.cleintCashe[action.payload.value] = action.payload.label;
        },
        setClientLoadState: (
            state,
            action: PayloadAction<{ state: 'pending' | 'fulfilled' | 'error'; id: string; error?: string }>,
        ) => {
            if (state.cobrend.clients) {
                const clientIndex = state.cobrend.clients?.findIndex((client) => client.clientId === action.payload.id);
                if (clientIndex !== -1) {
                    state.cobrend.clients[clientIndex].loadState = action.payload.state;
                    if (action.payload.error) {
                        state.cobrend.clients[clientIndex].error = action.payload.error;
                    }
                }
            }
        },
        clearCobrendData: (state) => {
            state.cobrend.cleintCashe = {};
            state.cobrend.clients = [];
            state.cobrend.mainData = undefined;
        },
    },
});

export const {
    setColumns,
    setDefaultItems,
    resetDefaultItems,
    setChoosedCols,
    setCobrendMainData,
    addCobrendMember,
    cacheClient,
    setClientLoadState,
    deleteCobrendMember,
    clearCobrendData,
} = contractSlice.actions;
