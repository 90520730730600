import { Table, ZeroState } from '@adtech/ui';
import { ArrowRightOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useGetErirMessagesMutation } from 'pages/MainPage/model/creativeStatApi';
import { ErirMessagesStatuses, IErirMessages } from 'pages/MainPage/model/creativesStatTypes';
import RangeSelect from 'pages/MainPage/ui/NotificationCenter/DateFilters/RangeSelect';
import React, { FC, MutableRefObject, useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useInfiniteScroll } from 'shared';
import EmptyStatTable from 'shared/assets/EmptyStatTable.svg';
import './ErirMessagesTable.less';
import { erirMessagesTableCols, erirMessagesTableColsWithoutStatus } from './ErirMessagesLib';

const ErirMessagesTable: FC<{ entityId?: string }> = ({ entityId }) => {
    const [getMessages, { data, isLoading }] = useGetErirMessagesMutation();
    const [dateValue, setDateValue] = useState<(moment.Moment | null)[]>([null, null]);
    const [messages, setMessages] = useState<IErirMessages[]>([]);
    const [page, setPage] = useState(1);
    const triggerRef = useRef() as MutableRefObject<HTMLDivElement>;
    const location = useLocation();
    const mainPage = location.pathname === '/';
    const loadMoreData = async () => {
        if (isLoading) return;
        if (data?.data.length === 0) return;
        getMessages({
            page,
            startDate: dateValue.at(0)?.format('YYYY-MM-DD'),
            endDate: dateValue.at(1)?.format('YYYY-MM-DD'),
            entityId: entityId || undefined,
        })
            .unwrap()
            .then((newData) => {
                setMessages((prev) => [...prev, ...newData.data]);
                setPage((prev) => prev + 1);
            });
    };
    useEffect(() => {
        getMessages({
            status: !mainPage ? undefined : ErirMessagesStatuses.NEW,
            startDate: dateValue.at(0)?.format('YYYY-MM-DD'),
            endDate: dateValue.at(1)?.format('YYYY-MM-DD'),
            entityId: entityId || undefined,
        })
            .unwrap()
            .then((newData) => {
                setMessages(newData.data);
            });
    }, [dateValue]);
    useInfiniteScroll({
        callback: loadMoreData,
        wrapperRef: document.querySelector('#erir-table > div.SBA-table-container > div.SBA-table-body'),
        triggerRef,
    });
    return (
        <>
            <RangeSelect setDateValue={setDateValue} dateValue={dateValue} />
            <Table
                columns={
                    mainPage ? erirMessagesTableColsWithoutStatus(setMessages) : erirMessagesTableCols(setMessages)
                }
                dataSource={messages}
                className="erir-table messages"
                id="erir-table"
                loading={isLoading}
                scroll={{ x: 'max-content', y: mainPage ? '25vh' : '40vh' }}
                pagination={false}
                onRow={(_, index) => {
                    if (index === messages.length - 1) {
                        return {
                            ref: triggerRef,
                            onClick: () => {
                                return undefined;
                            },
                        };
                    }
                    return {};
                }}
                title={
                    mainPage
                        ? () => (
                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <span>Новые сообщения от ЕРИР</span>
                                  <Link to="/notification-center?tab=messages">
                                      <span>Все сообщения</span>
                                      <ArrowRightOutlined />
                                  </Link>
                              </div>
                          )
                        : undefined
                }
                locale={{
                    emptyText: () => {
                        return (
                            <ZeroState
                                icon={<EmptyStatTable />}
                                title="У вас нет новых сообщений"
                                description="Полный список всех сообщений вы можете посмотреть по кнопке в центре уведомлений"
                            />
                        );
                    },
                }}
            />
        </>
    );
};

export default ErirMessagesTable;
