// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.layout {
  width: 100%;
  height: 100vh;
  background-color: black;
}
.layout:after {
  content: '';
  position: absolute;
  bottom: 1%;
  left: -4%;
  z-index: 0;
  width: 30%;
  height: 35%;
  border-radius: 597px;
  background: #48a3ed;
  opacity: 0.5;
  filter: blur(185.8366px);
}
.layout:before {
  content: '';
  position: absolute;
  top: -40%;
  right: 0;
  z-index: 0;
  width: 50%;
  height: 60%;
  border-radius: 771px;
  background: #48a3ed;
  opacity: 0.5;
  filter: blur(240px);
}
.ant-layout-content {
  z-index: 1;
  height: calc(100vh - 64px);
  padding: 0 24px 24px 0;
}
.page-wrapper {
  flex-grow: 1;
  flex-shrink: 100;
  overflow: auto;
  width: 100%;
  height: 100%;
  padding: 24px;
  border-radius: 16px;
  background: var(--b-ground, #e8ecf2);
}
`, "",{"version":3,"sources":["webpack://./src/widgets/Layout/Layout.less"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,aAAA;EACA,uBAAA;AACJ;AACI;EACI,WAAA;EACA,kBAAA;EACA,UAAA;EACA,SAAA;EACA,UAAA;EACA,UAAA;EACA,WAAA;EACA,oBAAA;EACA,mBAAA;EACA,YAAA;EACA,wBAAA;AACR;AAEI;EACI,WAAA;EACA,kBAAA;EACA,SAAA;EACA,QAAA;EACA,UAAA;EACA,UAAA;EACA,WAAA;EACA,oBAAA;EACA,mBAAA;EACA,YAAA;EACA,mBAAA;AAAR;AAIA;EACI,UAAA;EACA,0BAAA;EACA,sBAAA;AAFJ;AAKA;EACI,YAAA;EACA,gBAAA;EACA,cAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,oCAAA;AAHJ","sourcesContent":[".layout {\n    width: 100%;\n    height: 100vh;\n    background-color: black;\n\n    &:after {\n        content: '';\n        position: absolute;\n        bottom: 1%;\n        left: -4%;\n        z-index: 0;\n        width: 30%;\n        height: 35%;\n        border-radius: 597px;\n        background: #48a3ed;\n        opacity: .5;\n        filter: blur(185.8366px);\n    }\n\n    &:before {\n        content: '';\n        position: absolute;\n        top: -40%;\n        right: 0;\n        z-index: 0;\n        width: 50%;\n        height: 60%;\n        border-radius: 771px;\n        background: #48a3ed;\n        opacity: .5;\n        filter: blur(240px);\n    }\n}\n\n.ant-layout-content {\n    z-index: 1;\n    height: calc(100vh - 64px);\n    padding: 0 24px 24px 0;\n}\n\n.page-wrapper {\n    flex-grow: 1;\n    flex-shrink: 100;\n    overflow: auto;\n    width: 100%;\n    height: 100%;\n    padding: 24px;\n    border-radius: 16px;\n    background: var(--b-ground, #e8ecf2);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
