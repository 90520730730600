import { Alert } from '@adtech/ui';
import React, { FC } from 'react';

const SandboxAlert: FC = () => (
    <Alert
        banner
        // eslint-disable-next-line max-len
        message="ВНИМАНИЕ!: В данный момент вы находитесь в тестовой среде. Данные из тестовой среды не передаются в ЕРИР и не могут быть скопированы в промышленный контур"
        type="warning"
        style={{
            zIndex: 1,
        }}
    />
);

export default SandboxAlert;
