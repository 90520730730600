import { EStatus, Status, Tooltip } from '@adtech/ui';
import { ColumnsType } from 'antd/lib/table';
import Paragraph from 'antd/lib/typography/Paragraph';
import { ErirErrorsStatuses, IErirErrors } from 'pages/MainPage/model/creativesStatTypes';
import React from 'react';
import { Link } from 'react-router-dom';
import { Entities } from 'shared/lib/types';

export const erirErrorsTableCols: ColumnsType<IErirErrors> = [
    {
        title: 'Тип и название',
        className: 'name',
        render(value, row) {
            switch (row.entityType) {
                case Entities.ORG:
                    return <Link to={`/organizations/${row.entityId}`}>Организация {row.mainAttribute}</Link>;
                case Entities.PLA:
                    return <Link to={`/platforms/${row.entityId}`}>Площадка {row.mainAttribute}</Link>;
                case Entities.CON:
                    return <Link to={`/contracts/${row.entityId}`}>Договор {row.mainAttribute}</Link>;
                case Entities.CRE:
                    return <Link to={`/creatives/${row.entityId}`}>Креатив {row.mainAttribute}</Link>;
                case Entities.INV:
                    return <Link to={`/invoices/${row.entityId}`}>Акт {row.mainAttribute}</Link>;
                case Entities.STA:
                    return (
                        <Link to={`/creative-statistics/${row.entityId}`}>Статистика показов {row.mainAttribute}</Link>
                    );
                default:
                    return '–';
            }
        },
    },
    {
        title: '',
        className: 'status',
        render: (value, row) => {
            switch (row.status) {
                case ErirErrorsStatuses.NEW:
                    return <div className="status">Новое</div>;
                case ErirErrorsStatuses.RESOLVED:
                    return <Status status={EStatus.active} title="Исправлено" />;
                case ErirErrorsStatuses.RESOLVING:
                    return <Status status={EStatus.plan} title="В обработке" />;
                default:
                    return '';
            }
        },
    },
    {
        title: 'Код ошибки',
        className: 'code',
        render(value, row) {
            return (
                <Tooltip
                    title={
                        <Paragraph copyable style={{ color: 'white' }}>
                            {row.errorText}
                        </Paragraph>
                    }
                >
                    {row.errorCode}
                </Tooltip>
            );
        },
    },
    {
        title: 'Дата',
        className: 'date',
        render(value, row) {
            return row.date;
        },
    },
];

export const erirErrorsTableColsWithoutStatus: ColumnsType<IErirErrors> = [
    {
        title: 'Тип и название',
        className: 'name',
        render(value, row) {
            switch (row.entityType) {
                case Entities.ORG:
                    return <Link to={`/organizations/${row.entityId}`}>Организация {row.mainAttribute}</Link>;
                case Entities.PLA:
                    return <Link to={`/platforms/${row.entityId}`}>Площадка {row.mainAttribute}</Link>;
                case Entities.CON:
                    return <Link to={`/contracts/${row.entityId}`}>Договор {row.mainAttribute}</Link>;
                case Entities.CRE:
                    return <Link to={`/creatives/${row.entityId}`}>Креатив {row.mainAttribute}</Link>;
                case Entities.INV:
                    return <Link to={`/invoices/${row.entityId}`}>Акт {row.mainAttribute}</Link>;
                case Entities.STA:
                    return (
                        <Link to={`/creative-statistics/${row.entityId}`}>Статистика показов {row.mainAttribute}</Link>
                    );
                default:
                    return '–';
            }
        },
    },
    {
        title: 'Код ошибки',
        className: 'code',
        render(value, row) {
            return (
                <Tooltip
                    title={
                        <Paragraph copyable style={{ color: 'white' }}>
                            {row.errorText}
                        </Paragraph>
                    }
                >
                    {row.errorCode}
                </Tooltip>
            );
        },
    },
    {
        title: 'Дата',
        className: 'date',
        render(value, row) {
            return row.date;
        },
    },
];
