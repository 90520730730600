import { lazy } from 'react';
import { useGetDeleteRequestListMutation } from './model/deleteEntityApi';
import {
    setColumns,
    setDefaultItems,
    resetDefaultItems,
    setChoosedCols,
    deleteRequestSlice,
} from './model/deleteRequestSlice';
import { DeletingStatuses, deletingStatusMap } from './model/types';
import DeleteEntityBtn from './ui/DeleteEntityBtn';
import type { IDeleteRequestSlice, IDeleteRequest, IDeleteRequestList, IDeleteRequestListArgs } from './model/types';

const DeleteRequestRouter = lazy(() => import('./Routes/DeleteRequestRouter'));

export default DeleteEntityBtn;
export {
    IDeleteRequestSlice,
    IDeleteRequest,
    IDeleteRequestList,
    IDeleteRequestListArgs,
    DeletingStatuses,
    deletingStatusMap,
    setColumns,
    setDefaultItems,
    resetDefaultItems,
    setChoosedCols,
    deleteRequestSlice,
    DeleteRequestRouter,
    useGetDeleteRequestListMutation,
};
