import React, { FC } from 'react';
import './DataQualityTables.less';
import ErirErrorsTable from './Tables/ErrorsTable/ErirErrorsTable';
import ErirMessagesTable from './Tables/MessagesTables/ErirMessagesTable';

const DataQualityTables: FC = () => {
    return (
        <div className="table-container">
            <ErirMessagesTable />
            <ErirErrorsTable />
        </div>
    );
};

export default DataQualityTables;
