import { Button } from '@adtech/ui';
import { Header as AntHeader } from 'antd/lib/layout/layout';
import React, { FC, useCallback } from 'react';
import { Link } from 'react-router-dom';
import Logo from 'shared/assets/logo_ORD.svg';
import LogoutSvg from 'shared/assets/logout.svg';
import './Header.less';
import { useLogoutMutation } from 'shared/config/appConfig/api';
import { LangSwitcher } from 'shared/ui/LangSwitcher/LangSwitcher';
import DocsWidget from 'widgets/DocsWidget/DocsWidget';
import TGLink from 'widgets/TGLink/TGLink';

const Header: FC = () => {
    const [logout] = useLogoutMutation();
    const logoutHandler = useCallback(async () => {
        await logout().then(() => {
            window.location.reload();
        });
    }, []);
    return (
        <AntHeader className="header">
            <Link to="/" className="logo">
                <Logo />
            </Link>
            <div className="extras">
                <div className="langswitcher-wrapper">
                    <LangSwitcher />
                </div>
                <TGLink />
                <DocsWidget />
                <Button type="link" prefixIcon={<LogoutSvg />} onClick={logoutHandler} />
            </div>
        </AntHeader>
    );
};

export default Header;
