import { api } from 'shared/config/appConfig/api';
import {
    IGetInvoicesListSearchArgs,
    IGetInvoicesList,
    IInvoiceEntity,
    IInvoiceItemV2,
    IAllocationItemV1,
    IGetPlatformsByCintractIDResponse,
    IInvoice,
    IPlatformsListResponse,
    IPlatformsListArgs,
} from './types';

export const invoicesApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getInvoicesList: builder.mutation<IGetInvoicesList, { body: IGetInvoicesListSearchArgs }>({
            query: (arg) => ({
                url: 'lk/v1/invoices/list',
                method: 'POST',
                body: arg.body,
            }),
        }),
        getInvoicesById: builder.query<{ data?: IInvoiceEntity }, string | undefined>({
            query: (arg) => ({
                url: `lk/v1/invoices/${arg}`,
                method: 'GET',
            }),
        }),
        getAllocationV1: builder.query<{ data?: IAllocationItemV1[] }, string | undefined>({
            query: (arg) => ({
                url: `/lk/v1/invoices/${arg}/invoice_items`,
                method: 'GET',
            }),
        }),
        getAllocationV2: builder.query<IInvoiceItemV2, string | undefined>({
            query: (arg) => ({
                url: '/lk/v2/invoices/creatives/invoice_items',
                method: 'POST',
                body: {
                    invoiceId: arg,
                },
            }),
        }),
        getPlatformsByContract: builder.mutation<IPlatformsListResponse, IPlatformsListArgs>({
            query: (arg) => ({
                url: `/lk/v2/platforms/search`,
                method: 'POST',
                body: arg,
            }),
        }),
        createInvoiceV1: builder.mutation<{ data?: { id: string } }, IInvoice>({
            query: (arg) => ({
                url: 'lk/v1/invoices',
                body: arg,
                method: 'POST',
            }),
        }),
        editInvoiceV1: builder.mutation<{ data?: { id: string } }, { id?: string; body: IInvoice }>({
            query: (arg) => ({
                url: `/lk/v1/invoices/${arg.id}`,
                body: arg.body,
                method: 'PUT',
            }),
        }),
        createInvoiceV2: builder.mutation<{ data?: { id: string } }, IInvoice>({
            query: (arg) => ({
                url: '/lk/v2/invoices',
                method: 'POST',
                body: arg,
            }),
        }),
        editInvoiceV2: builder.mutation<{ data?: { id: string } }, { id?: string; body: IInvoice }>({
            query: (arg) => ({
                url: `/lk/v2/invoices/${arg.id}`,
                method: 'PUT',
                body: arg.body,
            }),
        }),
        createInvoiceCreativesV2: builder.mutation({
            query: (arg) => ({
                url: '/lk/v2/invoices/creatives',
                method: 'POST',
                body: arg,
            }),
        }),
        editInvoiceCreativesV2: builder.mutation({
            query: (arg) => ({
                url: '/lk/v2/invoices/creatives/',
                method: 'PUT',
                body: arg,
            }),
        }),
    }),
});

export const {
    useGetInvoicesListMutation,
    useGetInvoicesByIdQuery,
    useGetAllocationV1Query,
    useGetAllocationV2Query,
    useGetPlatformsByContractMutation,
    useCreateInvoiceV1Mutation,
    useEditInvoiceV1Mutation,
    useCreateInvoiceV2Mutation,
    useEditInvoiceV2Mutation,
    useCreateInvoiceCreativesV2Mutation,
    useEditInvoiceCreativesV2Mutation,
} = invoicesApi;
