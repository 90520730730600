import { ContractsRouter } from 'entities/Contracts';
import { CreativeRouter } from 'entities/Creatives';
import { InvoicesRouter } from 'entities/Invoices';
import { KeysRouter } from 'entities/Keys';
import { MassLoadRouter } from 'entities/MassLoad';
import { OrganizationRouter } from 'entities/Organization';
import { PlatformRouter } from 'entities/Platforms';
import { ReportsRouter } from 'entities/Statistics/ReportPage';
import { UserAdminRouter } from 'entities/UserAdmin';
import { WiewStatisticsRouter } from 'entities/ViewStatistics';
import { DeleteRequestRouter } from 'features/DeleteEntity';
import { MainPage, NotificationCenter } from 'pages/MainPage';
import React from 'react';
import { RouteProps } from 'react-router-dom';
import ForbiddenPage from 'widgets/ForbiddenPage/ForbiddenPage';
import NotFoundPage from 'widgets/NotFoundPage/NotFoundPage';

export enum AppRoutes {
    MAIN = 'main',
    ORG = 'organizations',
    CON = 'contracts',
    CRE = 'creative',
    INV = 'invoices',
    REP = 'servicelog',
    STA = 'creative-statistics',
    PLA = 'platforms',
    KEY = 'private',
    USERS = 'users',
    DELETE_REQUEST = 'delete-request',
    BATCH = 'batch',
    NOTIFICATION_CENTER = 'notification-center',
    NOT_FOUND = 'not-found',
    FORBIDDEN = 'forbidden',
}

export const RoutePath: Record<AppRoutes, string> = {
    [AppRoutes.MAIN]: '/',
    [AppRoutes.ORG]: '/organizations/*',
    [AppRoutes.CON]: '/contracts/*',
    [AppRoutes.CRE]: '/creatives/*',
    [AppRoutes.INV]: '/invoices/*',
    [AppRoutes.REP]: '/servicelog/*',
    [AppRoutes.STA]: '/creative-statistics/*',
    [AppRoutes.PLA]: '/platforms/*',
    [AppRoutes.KEY]: '/private/*',
    [AppRoutes.USERS]: '/account/*',
    [AppRoutes.DELETE_REQUEST]: '/delete-request/*',
    [AppRoutes.BATCH]: '/batch/*',
    [AppRoutes.NOTIFICATION_CENTER]: 'notification-center',
    [AppRoutes.FORBIDDEN]: '/forbidden-page',
    [AppRoutes.NOT_FOUND]: '/*',
};

export const routeConfig: Record<AppRoutes, RouteProps> = {
    [AppRoutes.MAIN]: {
        path: RoutePath.main,
        element: <MainPage />,
    },
    [AppRoutes.ORG]: {
        path: RoutePath.organizations,
        element: <OrganizationRouter />,
    },
    [AppRoutes.CON]: {
        path: RoutePath.contracts,
        element: <ContractsRouter />,
    },
    [AppRoutes.CRE]: {
        path: RoutePath.creative,
        element: <CreativeRouter />,
    },
    [AppRoutes.INV]: {
        path: RoutePath.invoices,
        element: <InvoicesRouter />,
    },
    [AppRoutes.REP]: {
        path: RoutePath.servicelog,
        element: <ReportsRouter />,
    },
    [AppRoutes.STA]: {
        path: RoutePath['creative-statistics'],
        element: <WiewStatisticsRouter />,
    },
    [AppRoutes.PLA]: {
        path: RoutePath.platforms,
        element: <PlatformRouter />,
    },
    [AppRoutes.KEY]: {
        path: RoutePath.private,
        element: <KeysRouter />,
    },
    [AppRoutes.USERS]: {
        path: RoutePath.users,
        element: <UserAdminRouter />,
    },
    [AppRoutes.DELETE_REQUEST]: {
        path: RoutePath['delete-request'],
        element: <DeleteRequestRouter />,
    },
    [AppRoutes.BATCH]: {
        path: RoutePath.batch,
        element: <MassLoadRouter />,
    },
    [AppRoutes.NOTIFICATION_CENTER]: {
        path: RoutePath['notification-center'],
        element: <NotificationCenter />,
    },
    [AppRoutes.FORBIDDEN]: {
        path: RoutePath.forbidden,
        element: <ForbiddenPage />,
    },
    [AppRoutes.NOT_FOUND]: {
        path: RoutePath['not-found'],
        element: <NotFoundPage />,
    },
};
