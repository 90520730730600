export enum Entities {
    ORG = 'ORG',
    CON = 'CON',
    CRE = 'CRE',
    INV = 'INV',
    STA = 'STA',
    PLA = 'PLA',
    ICR = 'ICR',
}

export enum OrdStatus {
    APPROVED = 'APPROVED',
    PENDING = 'PENDING',
    DECLINED = 'DECLINED',
    ACCEPTED = 'ACCEPTED',
    DELETING = 'DELETING',
    ALL = 'ALL',
    ERR = 'StatusErr',
}

export interface IEntityInfo {
    created?: string;
    erirError?: string;
    erirRegDate?: string;
    fsmId?: string;
    fsmState?: string;
    mediaError?: string;
    mediaStatus?: string;
    owner?: string;
    retryN?: number;
    status?: string;
    type?: string;
    updated?: string;
    wakeupDt?: string;
}

export interface IStatus {
    created: boolean;
    einfo: IEntityInfo;
    id: string;
}
