import { api } from 'shared/config/appConfig/api';
import { IDeleteRequestList, IDeleteRequestListArgs } from './types';

interface IDeleteEntityArg {
    id: string;
    cause: string | null;
}

const deleteEntityApi = api.injectEndpoints({
    endpoints: (builder) => ({
        deleteEntity: builder.mutation<void, IDeleteEntityArg>({
            query: (arg) => ({
                url: `lk/v2/deleting-requests`,
                method: 'POST',
                body: {
                    id: arg.id,
                    cause: arg.cause,
                },
            }),
        }),
        getDeleteRequestList: builder.mutation<IDeleteRequestList, IDeleteRequestListArgs>({
            query: (arg) => ({
                url: 'lk/v2/deleting-requests/list',
                method: 'POST',
                body: arg,
            }),
        }),
    }),
});

export const { useDeleteEntityMutation, useGetDeleteRequestListMutation } = deleteEntityApi;
