import { api } from 'shared/config/appConfig/api';
import {
    ICountriesListResponse,
    ICountrySearchResponse,
    ICreateEditResponse,
    IOrganisationEntity,
    IOrganisationEntityArg,
    IOrganisationEntityResponse,
    IOrganisationPostArg,
    IOrganisationPutArg,
    IOrganizationPlatform,
} from './types';

const baseUrl = '/lk/v1/organizations';

export const organizationApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getOrganizationsList: builder.mutation<IOrganisationEntityResponse, IOrganisationEntityArg>({
            query: (args) => ({
                url: `${baseUrl}/list`,
                method: 'POST',
                body: args.body,
            }),
        }),
        getCountries: builder.query<ICountriesListResponse, void>({
            query: () => ({
                url: `${baseUrl}/countries`,
                method: 'GET',
            }),
        }),
        getOrganizationById: builder.query<{ data: IOrganisationEntity }, string | undefined>({
            query: (id) => ({
                url: `${baseUrl}/${id}`,
                method: 'GET',
            }),
        }),
        getPlatforms: builder.query<{ data: IOrganizationPlatform[] }, string | undefined>({
            query: (id) => ({
                url: `${baseUrl}/${id}/platforms`,
                method: 'GET',
            }),
        }),
        searchCountry: builder.query<ICountrySearchResponse, string | undefined>({
            query: (arg) => ({
                url: `${baseUrl}/country`,
                params: { name: arg },
            }),
        }),
        createOrganization: builder.mutation<ICreateEditResponse, IOrganisationPostArg>({
            query: (arg) => ({
                url: baseUrl,
                method: 'POST',
                body: arg.body,
            }),
        }),
        editOrganization: builder.mutation<ICreateEditResponse, IOrganisationPutArg>({
            query: (arg) => ({
                url: `${baseUrl}/${arg.id}`,
                method: 'PUT',
                body: arg.body,
            }),
        }),
        setSelfOrganization: builder.mutation<void, string | undefined>({
            query: (arg) => ({
                url: '/lk/v1/selfOrganization',
                method: 'POST',
                body: {
                    organizationId: arg,
                },
            }),
        }),
        getSelfOrganization: builder.query<IOrganisationEntity, any>({
            query: () => ({
                url: '/lk/v1/selfOrganization',
                method: 'GET',
            }),
        }),
    }),
});

export const {
    useGetOrganizationsListMutation,
    useGetCountriesQuery,
    useGetOrganizationByIdQuery,
    useGetPlatformsQuery,
    useSearchCountryQuery,
    useCreateOrganizationMutation,
    useEditOrganizationMutation,
    useGetSelfOrganizationQuery,
    useSetSelfOrganizationMutation,
} = organizationApi;
