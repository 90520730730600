import { MutableRefObject, useEffect, useRef } from 'react';

export interface IUseInfiniteScrollOptions {
    callback?: () => void;
    triggerRef: MutableRefObject<HTMLElement>;
    wrapperRef: HTMLElement | null;
}

export function useInfiniteScroll({ callback, wrapperRef, triggerRef }: IUseInfiniteScrollOptions) {
    const observer = useRef<IntersectionObserver | null>(null);

    useEffect(() => {
        const triggerElement = triggerRef.current;
        if (callback && triggerElement) {
            const options = {
                root: wrapperRef,
                rootMargin: '2px',
                threshold: 1.0,
            };
            observer.current = new IntersectionObserver(([entry]) => {
                if (entry.isIntersecting) {
                    callback();
                }
            }, options);
            observer.current.observe(triggerElement);
        }

        return () => {
            if (observer.current && triggerElement) {
                observer.current.unobserve(triggerElement);
            }
        };
    }, [callback, triggerRef, wrapperRef]);
}
