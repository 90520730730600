import { PageHeader, Tabs } from '@adtech/ui';
import React, { FC, useState } from 'react';
import { useQueryParams } from 'shared/lib/hooks/useSearchParams';
import ErirErrorsTable from '../DataQuality/Tables/ErrorsTable/ErirErrorsTable';
import ErirMessagesTable from '../DataQuality/Tables/MessagesTables/ErirMessagesTable';

const NotificationCenter: FC = () => {
    const [urlParams, setParam] = useQueryParams();
    const [tab, setTab] = useState<'errors' | 'messages'>(urlParams.get('tab') as 'errors' | 'messages');
    return (
        <>
            <PageHeader
                title="Центр уведомлений"
                extra={[]}
                style={{ marginBottom: '24px' }}
                back={{ to: '/', title: 'Панель управления' }}
            />
            <Tabs
                options={[
                    {
                        value: 'messages',
                        label: 'Сообщения от ЕРИР',
                    },
                    {
                        value: 'errors',
                        label: 'Ошибки',
                    },
                ]}
                value={tab}
                onChange={(value) => {
                    setTab(value as 'errors' | 'messages');
                    setParam('tab', value);
                }}
                style={{ marginBottom: '24px' }}
            />
            {tab === 'messages' && <ErirMessagesTable />}
            {tab === 'errors' && <ErirErrorsTable />}
        </>
    );
};

export default NotificationCenter;
