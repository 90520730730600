import { lazy } from 'react';
import { useGetKeysListMutation, useCreateKeyMutation, useRevokeKeyMutation } from './model/keysApi';
import type { IKeyCreateArg, IKeysCreateResponse, IKeysEntity, IKeysListResponse, IKeysListtArg } from './model/types';

const KeysRouter = lazy(() => import('./Routes/KeysRoute'));

export {
    KeysRouter,
    useGetKeysListMutation,
    useCreateKeyMutation,
    useRevokeKeyMutation,
    IKeyCreateArg,
    IKeysCreateResponse,
    IKeysEntity,
    IKeysListResponse,
    IKeysListtArg,
};
