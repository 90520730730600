import { useSearchParams } from 'react-router-dom';

export const useQueryParams = (): [URLSearchParams, (key: string, value: any) => void, (key?: string) => void] => {
    const [urlParams, setUrlParams] = useSearchParams();
    const setParam = (key: string, value: any) => {
        urlParams.set(key as string, value);
        setUrlParams(urlParams);
    };
    const deleteParam = (key?: string) => {
        if (key) {
            urlParams.delete(key);
            setUrlParams(urlParams);
        } else {
            setUrlParams('');
        }
    };
    return [urlParams, setParam, deleteParam];
};
