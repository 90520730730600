import { EStatus, TTableSettingsListItemProps } from '@adtech/ui';
import { ColumnsType } from 'antd/lib/table';
import { Entities } from 'shared/lib/types';

export const allowedTypes = ['xls', 'xlsx', 'csv'];

export enum BatchStatuses {
    NEW = 'NEW',
    PROCESSING = 'PROCESSING',
    SUSPENDED = 'SUSPENDED',
    SUCCESS = 'SUCCESS',
    FAILED = 'FAILED',
}

export interface IBatchEntity {
    id: string | null;
    url: string | null;
    status: BatchStatuses | null;
    type: Entities | null;
    createDate: string | null;
    estimateDate: string | null;
    endDate: string | null;
    processed: number;
    total: number;
}

export interface IBatchEntityList {
    data: IBatchEntity[];
    meta: {
        limit: number;
        page: number;
        total: number;
    };
}

export interface IBatchEntityListArgs extends IBatchEntity {
    limit: number;
    page: number;
    sorting: Array<string> | undefined | null;
}

export interface IMassLoadSlice {
    columns?: ColumnsType<IBatchEntity>;
    defualtItems: TTableSettingsListItemProps[];
    choosedCols?: ColumnsType<IBatchEntity>;
}

export const batchStatusMap: {
    [Key in keyof typeof BatchStatuses]: { label: string; adtechStatus: EStatus };
} = {
    [BatchStatuses.NEW]: {
        adtechStatus: EStatus.end,
        label: 'Партия создана',
    },
    [BatchStatuses.PROCESSING]: {
        adtechStatus: EStatus.plan,
        label: 'Элементы созданы',
    },
    [BatchStatuses.SUSPENDED]: {
        adtechStatus: EStatus.inactive,
        label: 'Временно приостановлено',
    },
    [BatchStatuses.SUCCESS]: {
        adtechStatus: EStatus.active,
        label: 'Все элементы обработаны',
    },
    [BatchStatuses.FAILED]: {
        adtechStatus: EStatus.decline,
        label: 'Партия не обработана',
    },
};
