import { Select } from '@adtech/ui';
import React, { memo, useState } from 'react';
import i18n from 'shared/config/i18n/i18n';

interface ILangSwitcherProps {
    className?: string;
}

export const LangSwitcher = memo(({ className }: ILangSwitcherProps) => {
    const [lang, setLang] = useState(i18n.language || 'ru');
    const toggle = async (value: string) => {
        setLang(value);
        i18n.changeLanguage(value);
    };

    return (
        <Select
            size="small"
            value={lang}
            className={className}
            defaultValue="ru"
            options={[
                {
                    label: 'RU',
                    value: 'ru',
                },
                {
                    label: 'EN',
                    value: 'en',
                },
            ]}
            onChange={(value) => toggle(value)}
        />
    );
});
