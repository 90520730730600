import { TTableSettingsListItemProps } from '@adtech/ui';
import { ColumnsType } from 'antd/lib/table';
import React, { ReactNode } from 'react';
import { IEntityInfo, IStatus } from 'shared/lib/types';

export interface IContract {
    actionType?: string | null;
    amount?: string | null | number;
    clientId?: string | null;
    contractId?: string | null;
    contractorId?: string | null;
    date?: string | null;
    id?: string | null;
    isRegReport?: boolean | null;
    isVat?: boolean | null;
    number?: string | null;
    subjectType?: string | null;
    type?: string | null;
    agentActingForPublisher?: boolean | null;
    cid?: string | null;
    endDate?: string | null;
}

export interface IContractEntity {
    clientName?: string;
    contract?: IContract;
    contractorName?: string;
    entity?: IEntityInfo;
    parentNumber?: string;
}

export interface IContractByIdResponse {
    data?: IContractEntity;
}

export interface IGetContractsList {
    data?: IContractEntity[];
    meta: {
        limit: number;
        page: number;
        total: number;
    };
}

export interface IContractPostarg {
    body: IContract;
}

export interface IContractPutArg {
    id?: string;
    body: IContract;
}

export interface IContractsCreateEditResponse {
    data?: IStatus;
}

export interface IContractSearchParams {
    limit?: number;
    page?: number;
    status?: Array<string> | string | null;
    erirRegDate?: string | null;
    created?: string | null;
    sorting?: Array<string> | null | undefined;
    id?: string[] | string | null;
    date?: string | null;
    clientId?: Array<string> | string | null;
    contractId?: string;
    contractorId?: Array<string> | string | null;
    number?: string | null;
}

export interface IContractsListArgs {
    body: IContractSearchParams;
}

interface ICobrend {
    mainData: Omit<IContract, 'clientId' | 'isVat' | 'amount'> | undefined;
    clients:
        | {
              clientId: string;
              isVat: boolean;
              amount: number;
              loadState: 'pending' | 'fulfilled' | 'error';
              error?: string;
          }[]
        | undefined;
    cleintCashe: { [key: string]: string };
}

export type TContractBatchConfig = Record<keyof Omit<IContract, 'id'>, { label: string; tooltip: ReactNode }>;
export interface IContractsSlice {
    columns?: ColumnsType<IContractEntity>;
    defualtItems: TTableSettingsListItemProps[];
    choosedCols?: ColumnsType<IContractEntity>;
    batchConfigLabelMap: TContractBatchConfig;
    cobrend: ICobrend;
}

export const batchConfigLabelMap: TContractBatchConfig = {
    number: {
        label: 'Номер договора (строковый)',
        tooltip: (
            <div>
                Обязательное поле
                <br />
                Пример: Н-512
            </div>
        ),
    },
    type: {
        label: 'Тип договора (строковый)',
        tooltip: (
            <div>
                Обязательное поле
                <br />
                Возможные варианты:
                <ul>
                    <li>Посреднический договор</li>
                    <li>Договор оказания услуг</li>
                    <li>Дополнительное соглашение</li>
                </ul>
            </div>
        ),
    },
    subjectType: {
        label: 'Предмет договора (требования в разработке)',
        tooltip: (
            <div>
                Обязательное поле
                <br />
                Возможные варианты:
                <ul>
                    <li>Представительство</li>
                    <li>Договор на организацию распространения рекламы</li>
                    <li>Посредничество</li>
                    <li>Договор на распространение рекламы</li>
                    <li>Иное</li>
                </ul>
            </div>
        ),
    },
    clientId: {
        label: 'ИНН Заказчика (строковый)',
        tooltip: (
            <div>
                Обязательное поле
                <br />
                Пример: 501665196285
            </div>
        ),
    },
    contractorId: {
        label: 'ИНН Исполнителя (строковый)',
        tooltip: (
            <div>
                Обязательное поле
                <br />
                Пример: 501665196285
            </div>
        ),
    },
    contractId: {
        label: 'Идентификатор основного договора (строковый)',
        tooltip: <div>Обязательно если регистрируется дополнительное соглашение</div>,
    },
    date: {
        label: 'Дата договора (строковый)',
        tooltip: <div>Пример: 31.03.2024</div>,
    },
    actionType: {
        label: 'Действия посредника (строковый)',
        tooltip: (
            <div>
                Обязательно если регистрируется дополнительное соглашение
                <br />
                Возможные варианты:
                <ul>
                    <li>Действия в целях распространения рекламы</li>
                    <li>Заключение договоров</li>
                    <li>Коммерческое представительство</li>
                    <li>Иное</li>
                    <li>Нет значения</li>
                </ul>
            </div>
        ),
    },
    agentActingForPublisher: {
        label: 'Посредник действует в интересах заказчика или исполнителя',
        tooltip: (
            <div>
                Обязательно, если выбран тип договора Посреднический договор
                <br />
                Возможные варианты:
                <ul>
                    <li>Исполнитель</li>
                    <li>Заказчик</li>
                </ul>
            </div>
        ),
    },
    amount: {
        label: 'Сумма (денежный)',
        tooltip: <div>Пример: 836,05</div>,
    },
    isVat: {
        label: 'Признак НДС (строковый)',
        tooltip: (
            <div>
                Возможные варианты:
                <ul>
                    <li>Нет</li>
                    <li>Да</li>
                </ul>
            </div>
        ),
    },
    isRegReport: {
        label: 'Исполнитель отчитывается в ОРД (строковый)',
        tooltip: (
            <div>
                Возможные варианты:
                <ul>
                    <li>Нет</li>
                    <li>Да</li>
                </ul>
            </div>
        ),
    },
    cid: {
        label: 'CID (Строковый)',
        tooltip: <div>Обязательно, если основной договор зарегистрирован в другом ОРД</div>,
    },
    endDate: { label: '', tooltip: '' },
};
