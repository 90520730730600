import { api } from 'shared/config/appConfig/api';
import {
    ICreativeBrowseResponse,
    ICreativeSearchArgs,
    ICreativesCreateEditResponse,
    IGetCreativeByIdResponse,
    IKKTUSearch,
    IPostCreativeArgs,
    IPutCreativeArgs,
    ITargetRegion,
} from './types';

const baseUrl = 'lk/v1/creatives';

const creativeApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getCreativesList: builder.mutation<ICreativeBrowseResponse, { body: ICreativeSearchArgs }>({
            query: (arg) => ({
                url: `${baseUrl}/list`,
                method: 'POST',
                body: arg.body,
            }),
        }),
        getCreativeById: builder.query<IGetCreativeByIdResponse, string | undefined>({
            query: (arg) => ({
                url: `${baseUrl}/${arg}`,
                method: 'GET',
            }),
        }),
        getOkveds: builder.query<{ data: { id: string; label: string }[] }, string>({
            query: (queryArg) => ({
                url: `/lk/v1/creatives/businesstypes?query=${queryArg}`,
            }),
        }),
        getKKTUData: builder.query({
            query: () => ({
                url: '/lk/v1/ktu',
                method: 'GET',
            }),
        }),
        kktuSearch: builder.mutation<IKKTUSearch, { ids: string[] }>({
            query: (arg) => ({
                url: '/lk/v1/ktu/search',
                method: 'POST',
                body: arg,
            }),
        }),
        createCreative: builder.mutation<ICreativesCreateEditResponse, IPostCreativeArgs>({
            query: (arg) => ({
                url: `${baseUrl}`,
                method: 'POST',
                body: arg.body,
            }),
        }),
        editCreative: builder.mutation<ICreativesCreateEditResponse, IPutCreativeArgs>({
            query: (arg) => ({
                url: `${baseUrl}/${arg.id}`,
                method: 'PUT',
                body: arg.body,
            }),
        }),
        targetRegions: builder.mutation<{ data: ITargetRegion[] }, { codes: string[]; name: string }>({
            query: (arg) => ({
                url: `lk/v1/regions`,
                method: 'POST',
                body: arg,
            }),
        }),
        restartPublication: builder.mutation<void, { id: string; restartPublicationDate: string }>({
            query: (arg) => ({
                url: `${baseUrl}/${arg.id}/restart_publication`,
                method: 'POST',
                body: { restartPublicationDate: arg.restartPublicationDate },
            }),
        }),
        stopPublication: builder.mutation<void, { id: string; stopPublicationDate: string }>({
            query: (arg) => ({
                url: `${baseUrl}/${arg.id}/stop_publication`,
                method: 'POST',
                body: { stopPublicationDate: arg.stopPublicationDate },
            }),
        }),
    }),
});

export const {
    useGetCreativesListMutation,
    useGetCreativeByIdQuery,
    useGetOkvedsQuery,
    useCreateCreativeMutation,
    useEditCreativeMutation,
    useTargetRegionsMutation,
    useRestartPublicationMutation,
    useStopPublicationMutation,
    useGetKKTUDataQuery,
    useKktuSearchMutation,
} = creativeApi;
