import { Menu } from '@adtech/ui';
import { useAppSelector } from 'app/providers/StoreProvider';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { menuItems } from './model/menuItems';
import './Sider.less';

const Sider: FC = () => {
    const navigate = useNavigate();
    const auth = useAppSelector((selector) => selector.appSlice.isAuth);
    const { t } = useTranslation();
    const userPermData = useAppSelector((selector) => selector.userSlice.role);
    const transformPermissionData = useMemo(() => {
        const transformedData = userPermData.reduce((acc, val) => {
            if (acc.userPermissions && acc.roles) {
                acc.userPermissions = acc.userPermissions.concat(val.permission);
                acc.roles.push(val.role);
                return acc;
            }
            acc.userPermissions = val.permission || [];
            acc.roles = [val.role];
            return acc;
        }, {} as { [key: string]: Array<any> | undefined });
        return {
            userPermissions: transformedData.userPermissions,
            userRoles: transformedData.roles,
        };
    }, [userPermData]);
    const items = useMemo(() => {
        return userPermData.length !== 0
            ? menuItems.filter((item) => {
                  let showMenuIcon = true;
                  const { userPermissions, userRoles } = transformPermissionData;
                  if (item.roles?.entities && userPermissions) {
                      if (!userPermissions.includes(item.roles.entities)) {
                          showMenuIcon = false;
                          return showMenuIcon;
                      }
                  } else {
                      const filteredRoleArray = item.roles?.roles.filter((requiredRole) =>
                          userRoles?.includes(requiredRole),
                      );
                      if (filteredRoleArray?.length === 0) {
                          showMenuIcon = false;
                          return showMenuIcon;
                      }
                  }
                  return showMenuIcon;
              })
            : [];
    }, [transformPermissionData, userPermData.length]);
    return auth ? (
        <Menu
            items={items.map((item) => ({
                label: t(item.label),
                key: item.key,
                icon: item.icon,
                className: item.className,
                onClick: () => navigate(`${item.to}`),
            }))}
        />
    ) : null;
};

export default Sider;
