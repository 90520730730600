import { lazy } from 'react';
import getUserAccountStatus from './model/lib';
import { UserRoles, UserAccountStatus, SSOProvider } from './model/types';
import {
    useGetUsersListMutation,
    useGetUserQuery,
    useCreateLocalUserMutation,
    useCreateUserMutation,
    useEditUserMutation,
    useDeleteLocalUserMutation,
    useSavePermissionMutation,
    useDeletePermissionMutation,
    useDeleteUserMutation,
} from './model/userAdminApi';
import {
    setUserData,
    userSlice,
    setChoosedCols,
    setColumns,
    setDefaultItems,
    resetDefaultItems,
    setUserDetailPermission,
    deleteUserDetailPermission,
} from './model/userSlice';
import type {
    IUserEntity,
    TUserEntityArgs,
    TUserEntitySearchParams,
    TUserForm,
    IUsersListResponse,
    IUserEntityPerm,
    TEntities,
    TRoleRequiredType,
} from './model/types';

const UserAdminRouter = lazy(() => import('./Routes/UserAdminRouter'));

export {
    useGetUsersListMutation,
    useGetUserQuery,
    useCreateLocalUserMutation,
    useCreateUserMutation,
    useEditUserMutation,
    getUserAccountStatus,
    useDeleteLocalUserMutation,
    useSavePermissionMutation,
    useDeletePermissionMutation,
    useDeleteUserMutation,
    deleteUserDetailPermission,
    UserAdminRouter,
    TRoleRequiredType,
    UserRoles,
    IUserEntity,
    TUserEntityArgs,
    TUserEntitySearchParams,
    UserAccountStatus,
    TUserForm,
    IUsersListResponse,
    IUserEntityPerm,
    TEntities,
    SSOProvider,
    setUserData,
    userSlice,
    setChoosedCols,
    setColumns,
    setDefaultItems,
    resetDefaultItems,
    setUserDetailPermission,
};
