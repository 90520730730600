import { Loader } from '@adtech/ui';
import React, { FC } from 'react';

const AppLoad: FC = () => (
    <div
        style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50%',
            width: '100%',
        }}
    >
        <Loader />
    </div>
);

export default AppLoad;
