import { isRejectedWithValue, Middleware } from '@reduxjs/toolkit';
import { Modal, notification } from 'antd';
import { organizationApi } from 'entities/Organization/model/organizationsApi';
import React from 'react';
import { api } from 'shared/config/appConfig/api';
import { logout, setIs401 } from 'shared/config/appConfig/appSlice';
import i18n from 'shared/config/i18n/i18n';
// eslint-disable-next-line import/no-cycle
import { store } from '../config/store';
import CopyErrorBtn from './CopyErrorBtn';

const errordMiddleware: Middleware = () => (next) => (action) => {
    if (isRejectedWithValue(action)) {
        if (action.payload.data !== null && action.payload.data.detail) {
            notification.error({
                message: <CopyErrorBtn detail={action.payload.data.detail} requestId={action.payload.data.requestId} />,
            });
        }
        if (action.payload.status === 401 && !store.getState().appSlice.is401) {
            store.dispatch(setIs401());
            store
                .dispatch(api.endpoints.logout.initiate())
                .unwrap()
                .then(() => {
                    store.dispatch(logout());
                    Modal.confirm({
                        title: i18n.t('Auth.UnauthorizedTitle') as string,
                        content: i18n.t('Auth.UnauthorizedContent') as string,
                        cancelButtonProps: { style: { display: 'none' } },
                        onOk: () => {
                            store
                                .dispatch(organizationApi.endpoints.getOrganizationsList.initiate({}))
                                .unwrap()
                                .catch((error) => {
                                    const url = new URL(error.data.redirect);
                                    url.searchParams.set(
                                        'redirect_uri',
                                        // eslint-disable-next-line max-len
                                        `${window.location.origin}/public/v1/callback?lkRedirectUrl=${window.location.origin}`,
                                    );
                                    window.location.assign(url);
                                });
                        },
                    });
                });
        }
    }

    return next(action);
};

export default errordMiddleware;
