// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-container {
  display: flex;
  gap: 16px;
  margin-top: 24px;
}
.table-container .erir-table {
  width: 50%;
}
.table-container .erir-table .SBA-table#erir-table {
  padding: 24px 24px 32px 24px;
  gap: 24px;
  border-radius: 16px;
  background: var(--Snow, #FFF);
  /* Simple Shadow 1 */
  box-shadow: 0px 1px 2px 0px rgba(51, 63, 72, 0.25);
}
.table-container .erir-table .SBA-table#erir-table .SBA-table-title {
  padding: 0 !important;
}
.table-container .erir-table .SBA-table#erir-table .SBA-table-title div {
  display: flex;
  justify-content: space-between;
}
.table-container .erir-table .SBA-table#erir-table .SBA-table-title div a {
  display: flex;
  gap: 8px;
}
.table-container .erir-table .SBA-table#erir-table .SBA-table-title div span {
  font-size: 16px;
}
.table-container .erir-table .SBA-zerostate {
  margin: auto;
}
.table-container .erir-table .SBA-table-tbody {
  height: 25vh;
}
`, "",{"version":3,"sources":["webpack://./src/pages/MainPage/ui/DataQuality/DataQualityTables.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,SAAA;EACA,gBAAA;AACJ;AAJA;EAKQ,UAAA;AAER;AAPA;EAOY,4BAAA;EACA,SAAA;EACA,mBAAA;EACA,6BAAA;EAGV,oBAAoB;EAAV,kDAAA;AAEZ;AAfA;EAegB,qBAAA;AAGhB;AAlBA;EAiBgB,aAAA;EACA,8BAAA;AAIhB;AAtBA;EAoBoB,aAAA;EACA,QAAA;AAKpB;AA1BA;EAwBoB,eAAA;AAKpB;AA7BA;EA8BY,YAAA;AAEZ;AAhCA;EAiCY,YAAA;AAEZ","sourcesContent":[".table-container {\n    display: flex;\n    gap: 16px;\n    margin-top: 24px;\n    .erir-table {\n        width: 50%;\n        .SBA-table#erir-table {\n            padding: 24px 24px 32px 24px;\n            gap: 24px;\n            border-radius: 16px;\n            background: var(--Snow, #FFF);\n\n            /* Simple Shadow 1 */\n            box-shadow: 0px 1px 2px 0px rgba(51, 63, 72, 0.25);\n            .SBA-table-title {\n                padding: 0 !important;\n               div {\n                display: flex;\n                justify-content: space-between;\n                a {\n                    display: flex;\n                    gap: 8px;\n                }\n                span {\n                    font-size: 16px;\n                }\n               }\n            }\n        }\n        .SBA-zerostate {\n            margin: auto;\n        }\n        .SBA-table-tbody {\n            height: 25vh;\n        }\n    }\n}\n  "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
