// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.knowledge-base-cards {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  margin-top: 24px;
}
.knowledge-base-cards #knowledge-base-card.knowledge-base-card {
  border-radius: 16px;
}
.knowledge-base-cards #knowledge-base-card.knowledge-base-card .SBA-card-body .SBA-card-meta {
  display: flex;
  gap: 20px;
  flex-direction: row;
  align-items: center;
}
.knowledge-base-cards #knowledge-base-card.knowledge-base-card .SBA-card-body .SBA-card-meta .SBA-card-meta-avatar {
  margin-bottom: 0;
  height: 52px;
  width: 52px;
}
.knowledge-base-cards #knowledge-base-card.knowledge-base-card .SBA-card-body .SBA-card-meta .SBA-card-meta-detail {
  margin-bottom: 0;
}
`, "",{"version":3,"sources":["webpack://./src/pages/MainPage/ui/KnowledgeBase/KnowledgeBase.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,SAAA;EACA,qCAAA;EACA,kCAAA;EACA,gBAAA;AACJ;AANA;EAQQ,mBAAA;AACR;AATA;EAWgB,aAAA;EAEA,SAAA;EACA,mBAAA;EACA,mBAAA;AAAhB;AAfA;EAiBoB,gBAAA;EACA,YAAA;EACA,WAAA;AACpB;AApBA;EAsBoB,gBAAA;AACpB","sourcesContent":[".knowledge-base-cards {\n    display: grid;\n    gap: 20px;\n    grid-template-columns: repeat(3, 1fr);\n    grid-template-rows: repeat(2, 1fr);\n    margin-top: 24px;\n\n    #knowledge-base-card.knowledge-base-card {\n        border-radius: 16px;\n        .SBA-card-body {\n            .SBA-card-meta {\n                display: flex;\n                align-items: center;\n                gap: 20px;\n                flex-direction: row;\n                align-items: center;\n                .SBA-card-meta-avatar {\n                    margin-bottom: 0;\n                    height: 52px;\n                    width: 52px;\n                }\n                .SBA-card-meta-detail {\n                    margin-bottom: 0;\n                }\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
