import { api } from 'shared/config/appConfig/api';
import { IKeyCreateArg, IKeysCreateResponse, IKeysListResponse, IKeysListtArg } from './types';

const keysApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getKeysList: builder.mutation<IKeysListResponse, IKeysListtArg>({
            query: (arg) => ({
                url: '/lk/v1/tokens/browse',
                method: 'POST',
                body: arg,
            }),
        }),
        createKey: builder.mutation<IKeysCreateResponse, IKeyCreateArg>({
            query: (arg) => ({
                url: '/lk/v1/tokens',
                method: 'POST',
                body: arg,
            }),
        }),
        revokeKey: builder.mutation<void, string>({
            query: (arg) => ({
                url: `/lk/v1/tokens/${arg}`,
                method: 'DELETE',
            }),
        }),
    }),
});

export const { useGetKeysListMutation, useCreateKeyMutation, useRevokeKeyMutation } = keysApi;
