import { TableProps, notification } from 'antd';
import { store } from 'app/providers/StoreProvider';
import { ICreativeSearchArgs } from 'entities/Creatives';
import { organizationApi } from 'entities/Organization';
// import { IUserPerm, TEntities } from 'entities/UserAdmin/model/types';
import moment from 'moment';
import i18n from 'shared/config/i18n/i18n';
import { TCreativeAdditionalSearchParams } from './hooks/useCreativeSearchParams';
import { IState } from './hooks/useTableConfig';

const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/gm;

export const onTableChange = <EntityType>(setTableConfig: any, setParam?: any) => {
    const onChange: TableProps<EntityType>['onChange'] = (pagination, filters, sorter) => {
        setTableConfig((config: IState<EntityType>) => ({ ...config, pagination, sorter }));
        setParam('page', pagination.current?.toString());
    };
    return onChange;
};

export const getDateField = (date?: string): string => {
    const language = i18n.language === 'ru' ? 'ru' : 'en';
    const actionMoment = moment(date);
    const mainString = `${actionMoment.format('HH:mm  YYYY-MM-DD')}`;
    let diff: moment.DurationInputArg1 = 0;
    let diffMetric: moment.unitOfTime.DurationConstructor | undefined;
    if (moment().diff(actionMoment, 'seconds') > 0 && moment().diff(actionMoment, 'seconds') < 60) {
        diff = moment().diff(actionMoment, 'seconds');
        diffMetric = 'seconds';
    }
    if (moment().diff(actionMoment, 'minutes') < 60 && moment().diff(actionMoment, 'minutes') > 0) {
        diff = moment().diff(actionMoment, 'minutes');
        diffMetric = 'minutes';
    }
    if (moment().diff(actionMoment, 'hours') > 0 && moment().diff(actionMoment, 'hours') < 24) {
        diff = moment().diff(actionMoment, 'hours');
        diffMetric = 'hours';
    }
    if (moment().diff(actionMoment, 'days') > 0) {
        diff = moment().diff(actionMoment, 'days');
        diffMetric = 'days';
    }
    const diffString = moment.duration(-diff, diffMetric).locale(language).humanize(true);
    return `${mainString} (${diff === 1 ? `${diff} ` : ''}${diffString})`;
};

export const selectFilterOption = (input: string, option: any): boolean => {
    if (input.match(uuidRegex)) {
        return option!.value?.toLowerCase()?.includes(input?.toLowerCase());
    }
    return (option!.label as unknown as string)?.toLowerCase()?.includes(input?.toLowerCase());
};

export const checkIsSelfOrg = (callback: () => void, isOrgBtn?: boolean) => {
    const isSelfOrg = store.getState().appSlice.isSelfOrg;
    store
        .dispatch(organizationApi.endpoints.getOrganizationsList.initiate({}))
        .unwrap()
        .then((data) => {
            if ((data.data?.length === 0 && !isSelfOrg && isOrgBtn) || isSelfOrg) {
                callback();
            } else {
                notification.error({
                    message: 'Необходимо установить поставщика данных',
                });
            }
        });
};

export const tableHeightWithoutFilters = 'calc(60vh)';

export const buildCreativeSearchBody = (
    creativeSearchParams: TCreativeAdditionalSearchParams,
    debouncedValue: any,
    value: any,
    includeDeclined?: boolean,
): { body: ICreativeSearchArgs } => {
    return {
        body: {
            ...creativeSearchParams,
            marker: debouncedValue,
            id: !debouncedValue && value ? [value] : null,
            status: creativeSearchParams.status
                ? new Array(1).fill(creativeSearchParams.status)
                : ['APPROVED', 'ACCEPTED', 'PENDING', includeDeclined && 'DECLINED'],
            contractId: creativeSearchParams.contractId ? new Array(1).fill(creativeSearchParams.contractId) : null,
            form: creativeSearchParams.form ? new Array(1).fill(creativeSearchParams.form) : null,
            type: creativeSearchParams.type ? new Array(1).fill(creativeSearchParams.type) : null,
        },
    };
};

export const cidTooltip =
    'Идентификатор изначального договора - cid - это id, который формируется для изначального договора для передачи его между участниками цепочки. Может быть использован для регистрации актов и креативов в в любом ОРД';

// export const transformPermissionData = (stateUserData: IUserPerm[]) => {
//     const transformedData = stateUserData.reduce((acc, val) => {
//         acc[val.role] = val.permission;
//         return acc;
//     }, {} as { [key: string]: TEntities[] });
//     return {
//         roles: Object.keys(transformedData),
//     };
// };
