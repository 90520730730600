import { lazy } from 'react';
import {
    organizationApi,
    useGetOrganizationsListMutation,
    useGetCountriesQuery,
    useGetOrganizationByIdQuery,
    useGetPlatformsQuery,
    useSearchCountryQuery,
    useCreateOrganizationMutation,
    useEditOrganizationMutation,
    useGetSelfOrganizationQuery,
    useSetSelfOrganizationMutation,
} from './model/organizationsApi';
import {
    organizationsSlice,
    setColumns,
    setChoosedCols,
    setDefaultItems,
    resetDefaultItems,
    setPlatforms,
    addPlatforms,
    deleteOrganization,
    editPlatform,
} from './model/organizationsSlice';
import { OrgnisationType } from './model/types';
import type { ICountriesList, IORGSlice, IOrganizationPlatform, IOrganization, ICountryItem } from './model/types';

const OrganizationRouter = lazy(() => import('./Routes/OrganizationRouter'));

export {
    useGetOrganizationsListMutation,
    useGetCountriesQuery,
    useGetOrganizationByIdQuery,
    useGetPlatformsQuery,
    useSearchCountryQuery,
    useCreateOrganizationMutation,
    useEditOrganizationMutation,
    useGetSelfOrganizationQuery,
    useSetSelfOrganizationMutation,
    OrgnisationType,
    ICountriesList,
    IORGSlice,
    IOrganizationPlatform,
    IOrganization,
    ICountryItem,
    OrganizationRouter,
    organizationsSlice,
    setColumns,
    setChoosedCols,
    setDefaultItems,
    resetDefaultItems,
    setPlatforms,
    organizationApi,
    addPlatforms,
    deleteOrganization,
    editPlatform,
};
