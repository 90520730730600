// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dependency-entities a {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/features/DeleteEntity/ui/DeleteEntityBtn.less"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;AACJ","sourcesContent":[".dependency-entities a {\n    display: block;\n    white-space: nowrap;\n    text-overflow: ellipsis;\n    overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
