import { Dropdown, Menu } from 'antd';
import React, { FC } from 'react';
import DocsSvg from 'shared/assets/docs.svg';

const DocsWidget: FC = () => {
    const menuItems = [
        {
            key: 'user',
            label: (
                <a target="_blank" href="https://ord-lab.ru/documentation/9" rel="noreferrer">
                    Руководство пользователя
                </a>
            ),
        },
        {
            key: 'dev',
            label: (
                <a target="_blank" href="https://ord-lab.ru/documentation/15" rel="noreferrer">
                    Руководство разработчика
                </a>
            ),
        },
        {
            key: 'api',
            label: (
                <a target="_blank" href="https://ord-lab.ru/documentation/16" rel="noreferrer">
                    Руководство по API
                </a>
            ),
        },
        {
            key: 'swagger',
            label: (
                <a target="_blank" href="/swagger/index.html" rel="noreferrer">
                    ORD Swagger
                </a>
            ),
        },
        {
            key: 'api-v3',
            label: (
                <a target="_blank" href="/docs/dev-guide-api-v3.pdf" rel="noreferrer">
                    Руководство по API v3
                </a>
            ),
        },
        {
            key: 'swagger v3',
            label: (
                <a target="_blank" href="/swagger-api-v3/index.html" rel="noreferrer">
                    ORD Swagger v3
                </a>
            ),
        },
    ];
    const menu = () => <Menu items={menuItems} />;
    return (
        <Dropdown overlay={menu()}>
            <DocsSvg style={{ cursor: 'pointer' }} />
        </Dropdown>
    );
};

export default DocsWidget;
