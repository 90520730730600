import { configureStore, ReducersMapObject } from '@reduxjs/toolkit';
import { userSlice } from 'entities/UserAdmin';
import { CombinedState, Reducer } from 'redux';
import { api } from 'shared/config/appConfig/api';
import { appSlice, toggleFeature } from 'shared/config/appConfig/appSlice';
// eslint-disable-next-line import/no-cycle
import { featureFlagController, IFeatureFlag } from 'shared/config/appConfig/featureFlag';
import errordMiddleware from '../middlewares/errosHandling';
import createReducerManager from './ReducerManager';
import { IStateSchema } from './StateShema';

export function createReduxStore(initialState?: IStateSchema, asyncReducers?: ReducersMapObject<IStateSchema>) {
    const rootReducers: ReducersMapObject<IStateSchema> = {
        appSlice: appSlice.reducer,
        userSlice: userSlice.reducer,
        api: api.reducer,
        ...asyncReducers,
    };

    const reducerManager = createReducerManager(rootReducers);

    const store = configureStore({
        reducer: reducerManager.reduce as Reducer<CombinedState<IStateSchema>>,
        devTools: process.env.NODE_ENV !== 'production',
        preloadedState: initialState,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: false,
            })
                .concat(errordMiddleware)
                .concat(api.middleware),
    });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    store.reducerManager = reducerManager;

    return store;
}

export const store = createReduxStore();

// Enable store for dev feature toggling
export const enableDevFeatureToggling = (devStore: typeof store) => {
    if (window.location.origin.includes('ord-lab.dev') || window.location.origin.includes('localhost')) {
        localStorage.setItem('featureFlagController', JSON.stringify(devStore.getState().appSlice.featureFlag));
        (window as typeof window & { toggleFeature: (key: keyof IFeatureFlag) => void }).toggleFeature = (
            key: keyof IFeatureFlag,
        ) => {
            devStore.dispatch(toggleFeature(key));
        };
        (window as typeof window & { featuresList: () => void }).featuresList = () => {
            return Object.keys(featureFlagController);
        };
    }
};

export type TAppDispatch = ReturnType<typeof createReduxStore>['dispatch'];
