import { Table, ZeroState } from '@adtech/ui';
import { ArrowRightOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useGetErirErrorsMutation } from 'pages/MainPage/model/creativeStatApi';
import { ErirErrorsStatuses, IErirErrors } from 'pages/MainPage/model/creativesStatTypes';
import RangeSelect from 'pages/MainPage/ui/NotificationCenter/DateFilters/RangeSelect';
import React, { FC, MutableRefObject, useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useInfiniteScroll } from 'shared';
import EmptyStatTable from 'shared/assets/EmptyStatTable.svg';
import { erirErrorsTableCols, erirErrorsTableColsWithoutStatus } from './ErirErrorsLib';
import './ErirErrorsTable.less';

const ErirErrorsTable: FC<{ entityId?: string }> = ({ entityId }) => {
    const [getErrors, { data, isLoading }] = useGetErirErrorsMutation();
    const [dateValue, setDateValue] = useState<(moment.Moment | null)[]>([null, null]);
    const [errors, setErrors] = useState<IErirErrors[]>([]);
    const [page, setPage] = useState(1);
    const triggerRef = useRef() as MutableRefObject<HTMLDivElement>;
    const location = useLocation();
    const mainPage = location.pathname === '/';
    const loadMoreData = async () => {
        if (isLoading) return;
        if (data?.data.length === 0) return;
        getErrors({
            page,
            startDate: dateValue.at(0)?.format('YYYY-MM-DD'),
            endDate: dateValue.at(1)?.format('YYYY-MM-DD'),
            entityId: entityId || undefined,
        })
            .unwrap()
            .then((newData) => {
                setErrors((prev) => [...prev, ...newData.data]);
                setPage((prev) => prev + 1);
            });
    };

    useEffect(() => {
        getErrors({
            status: !mainPage ? undefined : ErirErrorsStatuses.NEW,
            startDate: dateValue.at(0)?.format('YYYY-MM-DD'),
            endDate: dateValue.at(1)?.format('YYYY-MM-DD'),
            entityId: entityId || undefined,
        })
            .unwrap()
            .then((newData) => {
                setErrors(newData.data);
            });
    }, [dateValue]);
    useInfiniteScroll({
        callback: loadMoreData,
        wrapperRef: document.querySelector('#erir-table > div.SBA-table-container > div.SBA-table-body'),
        triggerRef,
    });
    return (
        <>
            <RangeSelect setDateValue={setDateValue} dateValue={dateValue} />
            <Table
                columns={mainPage ? erirErrorsTableColsWithoutStatus : erirErrorsTableCols}
                dataSource={errors}
                loading={isLoading}
                className="erir-table errors"
                id="erir-table"
                scroll={{ x: 'max-content', y: mainPage ? '25vh' : '40vh' }}
                pagination={false}
                onRow={(_, index) => {
                    if (index === errors.length - 1) {
                        return {
                            ref: triggerRef,
                            onClick: () => {
                                return undefined;
                            },
                        };
                    }
                    return {};
                }}
                title={
                    mainPage
                        ? () => (
                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <span>Новые ошибки</span>
                                  <Link to="/notification-center?tab=errors">
                                      <span>Все ошибки</span>
                                      <ArrowRightOutlined />
                                  </Link>
                              </div>
                          )
                        : undefined
                }
                locale={{
                    emptyText: () => {
                        return (
                            <ZeroState
                                icon={<EmptyStatTable />}
                                title="У вас нет новых ошибок"
                                description="Полный список всех ошибок вы можете посмотреть по кнопке в центре уведомлений"
                            />
                        );
                    },
                }}
            />
        </>
    );
};

export default ErirErrorsTable;
