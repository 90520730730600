import { IEntityInfo, Entities } from 'shared/lib/types';

export interface ICreativeStatData {
    description?: string | null;
    externalId?: string | null;
    hash?: string | null;
    mediaUrl?: string | null;
    media?: {
        downloadUrl: string;
        name: string;
        previewUrl: string;
    };
    textData?: string | null;
    brand?: string | null;
    product?: string | null;
    fileId?: string | null;
}

export interface ITargetRegion {
    code: string;
    name: string;
}

export interface ICreativeStat {
    id?: string | null;
    type?: string | null;
    form?: string | null;
    selfPromotionOrganizationId?: string | null;
    contractId?: string[] | null;
    cid?: string[] | null;
    creativeOkveds?: string[] | null;
    description?: string | null;
    isNative?: boolean | null;
    isSocial?: boolean | null;
    url?: string[] | null;
    creativeData?: ICreativeStatData[] | null;
    erirIdType?: string | null;
    // brand?: string | null;
    // product?: string | null;
    targetAudience: {
        age: string[];
        sex: string[];
        geo: ITargetRegion[];
    };
}

export interface ICreativeStatEntity {
    creative?: ICreativeStat;
    contractNumber?: { [key: string]: string };
    organizationName?: string | null;
    entity?: IEntityInfo;
    marker?: string | null;
}

export interface ICreativeStatArgs {
    id?: string[] | string | null;
    limit?: number;
    page?: number;
    status?: Array<string> | string | null;
    contractId?: Array<string> | string | null;
    erirRegDate?: string | null;
    created?: string | null;
    type?: Array<string> | string | null;
    form?: Array<string> | string | null;
    sorting?: Array<string> | null;
    marker?: string;
    selfPromotionOrganizationId?: string | null;
    adsStatus?: 'actual' | 'expired' | 'stopped' | null;
}

export interface ICreativeStatBrowseResponse {
    data?: ICreativeStatEntity[];
    meta: { limit: number; page: number; total: number };
}

export interface IMeta {
    page: number;
    limit: number;
    total: number;
    next: string;
}

export enum ErirMessagesStatuses {
    NEW = 'NEW',
    READ = 'READ',
}

export interface IErirMessages {
    messageId: string;
    entityId: string;
    entityType: Entities;
    mainAttribute: string;
    attributes: string;
    title: string;
    message: string;
    createdAt: string;
    receivedAt: string;
    status: ErirMessagesStatuses;
}

export interface IErirMessagesSearchArgs {
    page?: number;
    limit?: number;
    sorting?: string;
    cursor?: string;
    namespaceId?: string;
    startDate?: string;
    endDate?: string;
    status?: string;
    entityType?: string;
    title?: string;
    createdAt?: string;
    messageId?: string;
    entityId?: string;
}

export enum ErirErrorsStatuses {
    NEW = 'NEW',
    RESOLVING = 'RESOLVING',
    RESOLVED = 'RESOLVED',
}

export interface IErirErrors {
    entityId: string;
    entityType: Entities;
    mainAttribute: string;
    attributes: string;
    date: string;
    errorCode: string;
    errorText: string;
    status: ErirErrorsStatuses;
}

export interface IErirErrorsSearchArgs {
    page?: number;
    limit?: number;
    sorting?: string;
    cursor?: number;
    namespaceId?: string;
    startDate?: string;
    endDate?: string;
    status?: ErirErrorsStatuses;
    errorCode?: string;
    entityType?: Entities;
    entityId?: string;
}
