import { UserRoles, TRoleRequiredType } from 'entities/UserAdmin';
import PlatformCreateEditPage from 'pages/Platforms/CreateEditPage';
import PlatformDetailPage from 'pages/Platforms/DetailPage';
import PlatformsListPage from 'pages/Platforms/ListPage';
import React from 'react';
import { RouteProps } from 'react-router-dom';

type TPlatformsRouteProps = RouteProps & {
    roles: TRoleRequiredType;
};

export enum PlatformRoutes {
    LIST = 'list',
    DETAILS = 'details',
    CREATE = 'create',
    EDIT = 'edit',
}

export const platformsPath: Record<PlatformRoutes, string> = {
    [PlatformRoutes.LIST]: '/',
    [PlatformRoutes.DETAILS]: '/:id',
    [PlatformRoutes.CREATE]: '/create',
    [PlatformRoutes.EDIT]: '/:id/edit',
};

export const platformsRouteConfig: Record<PlatformRoutes, TPlatformsRouteProps> = {
    [PlatformRoutes.LIST]: {
        path: platformsPath.list,
        element: <PlatformsListPage />,
        roles: {
            roles: [UserRoles.BusinessUser, UserRoles.BusinessReader],
            // entities: 'PLA',
        },
    },
    [PlatformRoutes.DETAILS]: {
        path: platformsPath.details,
        element: <PlatformDetailPage />,
        roles: {
            roles: [UserRoles.BusinessUser, UserRoles.BusinessReader],
            // entities: 'PLA',
        },
    },
    [PlatformRoutes.CREATE]: {
        path: platformsPath.create,
        element: <PlatformCreateEditPage />,
        roles: {
            roles: [UserRoles.BusinessUser],
            // entities: 'PLA',
        },
    },
    [PlatformRoutes.EDIT]: {
        path: platformsPath.edit,
        element: <PlatformCreateEditPage />,
        roles: {
            roles: [UserRoles.BusinessUser],
            // entities: 'PLA',
        },
    },
};
