/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Datepicker } from '@adtech/ui';
import { notification } from 'antd';
import moment from 'moment';
import React, { FC, useState } from 'react';
import './RangeSelect.less';
import { useLocation } from 'react-router-dom';

interface IRangeSelectProps {
    dateValue: (moment.Moment | null)[];
    setDateValue: React.Dispatch<React.SetStateAction<(moment.Moment | null)[]>>;
}

const RangeSelect: FC<IRangeSelectProps> = ({ setDateValue, dateValue }) => {
    const [value, setValue] = useState<string>('all');
    const location = useLocation();
    const dateButtons = [
        {
            label: 'Прошлый месяц',
            dateValue: [
                moment().startOf('month').subtract('month', 1).startOf('month'),
                moment().startOf('month').subtract('month', 1).endOf('month'),
            ],
            value: 'last-month',
        },
        {
            label: 'Текущий квартал',
            dateValue: [moment().startOf('quarter'), moment().endOf('quarter')],
            value: 'current-quarter',
        },
        {
            label: 'Прошлый квартал',
            dateValue: [
                moment().subtract('quarter', 1).startOf('quarter'),
                moment().subtract('quarter', 1).endOf('quarter'),
            ],
            value: 'last-quarter',
        },
        {
            label: 'Текущий год',
            dateValue: [moment().startOf('year'), moment().endOf('year')],
            value: 'current-year',
        },
        {
            label: 'За всё время',
            dateValue: [null, null],
            value: 'all',
        },
    ];
    if (!location.pathname.includes('/notification-center')) {
        return null;
    }
    return (
        <div className="range-picker">
            <div className="pickers">
                <Datepicker
                    value={dateValue.at(0)}
                    onChange={(pickedValue) => {
                        if (moment(pickedValue) > dateValue.at(1)! && dateValue.at(1) !== null) {
                            notification.warn({ message: 'Выбраны неверные даты' });
                            return;
                        }
                        setDateValue((prev) => [pickedValue, prev.at(1)!]);
                    }}
                />
                —
                <Datepicker
                    value={dateValue.at(1)}
                    onChange={(pickedValue) => {
                        if (moment(pickedValue) < dateValue.at(0)! && dateValue.at(0) !== null) {
                            notification.warn({ message: 'Выбраны неверные даты' });
                            return;
                        }
                        setDateValue((prev) => [prev.at(0)!, pickedValue]);
                    }}
                />
            </div>
            <ul className="date-buttons">
                {dateButtons.map((item) => (
                    <li
                        className={item.value === value ? 'checked' : ''}
                        onClick={() => {
                            setDateValue(item.dateValue);
                            setValue(item.value);
                        }}
                    >
                        <span>{item.label}</span>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default RangeSelect;
