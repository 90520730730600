import { TTableSettingsListItemProps } from '@adtech/ui';
import { ColumnsType } from 'antd/lib/table';

export enum UserAccountStatus {
    CREATED = 'CREATED',
    ACTIVATED = 'ACTIVATED',
    PERMREQ = 'PERMREQ',
    READY = 'READY',
    BLOCKED = 'BLOCKED',
}

export enum SSOProvider {
    KEYCLOAK = 'keycloak',
}

export interface IUserEntityPerm {
    id: string;
    name: string;
    role: string;
    type: 'namespace' | 'client';
}

export interface IUserEntity {
    id: string;
    status: UserAccountStatus;
    email: string;
    firstName: string;
    lastName: string;
    middleName: string | null;
    phoneNumber: string;
    datetimeCreated: string;
    datetimeUpdated: string | null;
    permissions: IUserEntityPerm[];
    type: SSOProvider;
}

export interface IUsersListResponse {
    data: IUserEntity[];
    meta: {
        total: number;
        page: number;
        limit: number;
    };
}

export interface ICreateUserResponse {
    data: IUserEntity;
}

export type TUserEntitySearchParams = {
    id?: string | null;
    status?: UserAccountStatus | UserAccountStatus[] | null;
    email?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    middleName?: string | null;
    phoneNumber?: string | null;
    limit?: number | null;
    page?: number | null;
    sorting?: string[] | null;
};

export type TUserEntityArgs = TUserEntitySearchParams;

export type TUserForm = {
    email: string | null;
    firstName: string | null;
    lastName: string | null;
    middleName: string | null;
    phoneNumber: string | null;
};

export type TUserCols = ColumnsType<IUserEntity>;

export enum UserRoles {
    ORDAdmin = 'ORDAdmin',
    ClientAdmin = 'ClientAdmin',
    NamespaceAdmin = 'NamespaceAdmin',
    BusinessUser = 'BusinessUser',
    BusinessReader = 'BusinessReader',
    ReportReader = 'ReportReader',
}

type TUserPermissions = {
    [Key in keyof typeof UserRoles]: string[];
};

export const UserPermissions: TUserPermissions = {
    ORDAdmin: ['ManageAccess', 'ManageStructure'],
    ClientAdmin: ['ManageAccess'],
    NamespaceAdmin: ['ManageAccess'],
    BusinessUser: ['ReadData', 'ModifyData', 'CreateData', 'Report', 'ManageUserToken'],
    BusinessReader: ['ReadData', 'Report'],
    ReportReader: ['Report'],
};

export type TEntities = 'ORG' | 'CON' | 'INV' | 'CRE' | 'STA' | 'ICR' | 'PLA';

export interface IUserPerm {
    role: UserRoles;
    permission: TEntities[] | null;
}

export interface IUserSlice {
    role: IUserPerm[];
    columns?: ColumnsType<IUserEntity>;
    defualtItems: TTableSettingsListItemProps[];
    choosedCols?: ColumnsType<IUserEntity>;
    userDetailPermission: IUserEntityPerm[] | null;
    permissions:
        | {
              role: UserRoles;
              permissionArea: string;
          }[]
        | null;
}

export type TRoleRequiredType = {
    roles: UserRoles[];
    entities?: TEntities;
};

export interface IGetNamespacesArg {
    limit: number;
    name: string;
}

export interface IGetNamespacesResponse {
    data: {
        id: string;
        name: string;
    }[];
}

export interface ISavePermissionArg {
    userId: string;
    type: string;
    roles: {
        role: string;
        permissionArea: string;
    }[];
}
