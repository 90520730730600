import { Status } from '@adtech/ui';
import React, { FC } from 'react';
import { BatchStatuses, batchStatusMap } from '../model/types';

interface IBatchStatusViewProps {
    status: BatchStatuses;
}

const BatchStatusView: FC<IBatchStatusViewProps> = (props) => {
    const { status } = props;
    return <Status title={batchStatusMap[status].label} status={batchStatusMap[status].adtechStatus} />;
};

export default BatchStatusView;
