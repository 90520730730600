import { Button } from '@adtech/ui';
import { Result, message } from 'antd';
import React, { FC } from 'react';
import './ErrorPage.less';

const ErrorPage: FC<{ error?: Error }> = ({ error }) => {
    return (
        <Result
            className="handleError"
            status="500"
            title="Произошла непредвиденная ошибка"
            subTitle={
                <div>
                    Попробуйте перезагрузить страницу или обратитесь в поддержку (
                    <Button
                        type="link"
                        style={{ fontSize: '12px' }}
                        onClick={() => {
                            navigator.clipboard.writeText(error?.message as string).then(
                                () => {
                                    message.success('Скопировано');
                                },
                                (err) => {
                                    message.error('Async: Could not copy text: ', err);
                                },
                            );
                        }}
                    >
                        скопировать ошибку
                    </Button>
                    )
                </div>
            }
            extra={<Button onClick={() => window.location.reload()}>Перезагрузить страницу</Button>}
        />
    );
};

export default ErrorPage;
