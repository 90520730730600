import { lazy } from 'react';
import {
    setColumns,
    setChoosedCols,
    setDefaultItems,
    resetDefaultItems,
    invoiceSlice,
    setDetalization,
    addDetalization,
    setInvoiceItemVersion,
    setAllocationItemV1,
    addAllocationItemV1,
    setAllocationItemV2,
    addPlatformCacheDat,
    setInvoiceAmount,
    addAllocationItemV2,
    setActingContract,
    setClientRole,
    editDetalization,
    editAllocationItemV1,
    deleteAllocation,
} from './model/invoiceSlice';
import {
    useGetInvoicesListMutation,
    useGetInvoicesByIdQuery,
    useGetAllocationV1Query,
    useGetAllocationV2Query,
    useGetPlatformsByContractMutation,
    useCreateInvoiceV1Mutation,
    useEditInvoiceV1Mutation,
    useCreateInvoiceV2Mutation,
    useEditInvoiceV2Mutation,
    useCreateInvoiceCreativesV2Mutation,
    useEditInvoiceCreativesV2Mutation,
} from './model/invoicesApi';
import type {
    IInvoiceEntity,
    IInvoiceSlice,
    IGetInvoicesListSearchArgs,
    IGetInvoicesList,
    IInvoiceItemV1,
    IInvoiceItemCreativeV1,
    IInvoiceItemV2,
    IInvoiceItemCreativePlatformV1,
    IAllocationItemV1,
    IInvoiceItemV2Entity,
    IInvoice,
} from './model/types';

const InvoicesRouter = lazy(() => import('./routes/InvoicesRouter'));

export {
    InvoicesRouter,
    setColumns,
    setChoosedCols,
    setDefaultItems,
    resetDefaultItems,
    invoiceSlice,
    setDetalization,
    addDetalization,
    setInvoiceItemVersion,
    setAllocationItemV1,
    addAllocationItemV1,
    setAllocationItemV2,
    addPlatformCacheDat,
    setInvoiceAmount,
    addAllocationItemV2,
    setActingContract,
    setClientRole,
    editDetalization,
    editAllocationItemV1,
    deleteAllocation,
    IInvoiceEntity,
    IInvoiceSlice,
    IGetInvoicesListSearchArgs,
    IGetInvoicesList,
    IInvoiceItemV1,
    IInvoiceItemCreativeV1,
    IInvoiceItemV2,
    IInvoiceItemCreativePlatformV1,
    IAllocationItemV1,
    IInvoiceItemV2Entity,
    IInvoice,
    useGetInvoicesListMutation,
    useGetInvoicesByIdQuery,
    useGetAllocationV1Query,
    useGetAllocationV2Query,
    useGetPlatformsByContractMutation,
    useCreateInvoiceV1Mutation,
    useEditInvoiceV1Mutation,
    useCreateInvoiceV2Mutation,
    useEditInvoiceV2Mutation,
    useCreateInvoiceCreativesV2Mutation,
    useEditInvoiceCreativesV2Mutation,
};
