import { api } from 'shared/config/appConfig/api';
import {
    IContractByIdResponse,
    IContractPostarg,
    IContractPutArg,
    IContractsCreateEditResponse,
    IContractsListArgs,
    IGetContractsList,
} from './types';

const baseUrl = '/lk/v1/contracts';

export const contractsApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getContractsList: builder.mutation<IGetContractsList, IContractsListArgs>({
            query: (arg) => ({
                url: `${baseUrl}/list`,
                method: 'POST',
                body: arg.body,
            }),
        }),
        getContractById: builder.query<IContractByIdResponse, string | undefined>({
            query: (arg) => ({
                url: `${baseUrl}/${arg}`,
                method: 'GET',
            }),
        }),
        createContracts: builder.mutation<IContractsCreateEditResponse, IContractPostarg>({
            query: (arg) => ({
                url: `${baseUrl}`,
                method: 'POST',
                body: arg.body,
            }),
        }),
        editContracts: builder.mutation<IContractsCreateEditResponse, IContractPutArg>({
            query: (arg) => ({
                url: `${baseUrl}/${arg.id}`,
                method: 'PUT',
                body: arg.body,
            }),
        }),
        createCid: builder.mutation<void, { contractId: string }>({
            query: (arg) => ({
                url: `${baseUrl}/cid`,
                method: 'POST',
                body: { contractId: arg.contractId },
            }),
        }),
    }),
});

export const {
    useGetContractsListMutation,
    useGetContractByIdQuery,
    useCreateContractsMutation,
    useEditContractsMutation,
    useCreateCidMutation,
} = contractsApi;
