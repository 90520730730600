import { Button, Modal } from '@adtech/ui';
import { Form, notification } from 'antd';
import CopyErrorBtn from 'app/providers/StoreProvider/middlewares/CopyErrorBtn';
import React, { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Entities, OrdStatus } from 'shared/lib/types';
import { useDeleteEntityMutation } from '../model/deleteEntityApi';
import { Causes } from '../model/types';
import CauseSelect from './DeleteCause/CauseSelect';
import CauseTextArea from './DeleteCause/CauseTextArea';
import './DeleteEntityBtn.less';

interface IDeleteEntityBtnProps {
    refetch: any;
    entityStatus: OrdStatus;
}

const getEntityName = (entityType: Entities) => {
    switch (entityType) {
        case Entities.ORG:
            return 'Организации';
        case Entities.PLA:
            return 'Площадки';
        case Entities.CON:
            return 'Договоры';
        case Entities.CRE:
            return 'Креативы';
        case Entities.INV:
            return 'Акты';
        case Entities.STA:
            return 'Статистика показов';
        default:
            return 'typeError';
    }
};

const getEntityLink = (entityType: Entities, id: string) => {
    switch (entityType) {
        case 'ORG':
            return `/organizations/${id}`;
        case 'PLA':
            return `/platforms/${id}`;
        case 'CON':
            return `/contracts/${id}`;
        case 'CRE':
            return `/creatives/${id}`;
        case 'INV':
            return `/invoices/${id}`;
        case 'STA':
            return `/creative-statistics/${id}`;
        default:
            return 'typeError';
    }
};

const DeleteEntityBtn: FC<IDeleteEntityBtnProps> = (props) => {
    const { refetch, entityStatus } = props;
    const { id } = useParams();
    const [open, setOpen] = useState(false);
    const [cause, setCause] = useState<string | null>(null);
    const [customCause, setCustomCause] = useState<string | null>(null);
    const [deleteEntity] = useDeleteEntityMutation();
    const hideDeleteBtn =
        entityStatus === OrdStatus.ACCEPTED ||
        entityStatus === OrdStatus.DECLINED ||
        entityStatus === OrdStatus.PENDING ||
        entityStatus === OrdStatus.DELETING;
    const handleClose = () => {
        setOpen(false);
    };
    const handleOk = () => {
        if (cause || customCause) {
            let requestCause = cause;

            if (requestCause === Causes.OTHER) {
                requestCause = customCause;
            }

            deleteEntity({
                id: id as string,
                cause: requestCause,
            })
                .unwrap()
                .then(() => {
                    setOpen(false);

                    notification.success({
                        message: 'Заявка на удаление успешно создана!',
                    });

                    refetch();
                })
                .catch((error) => {
                    if (error.data.detail.indexOf('entity have dependents') !== -1) {
                        const errorDescription = (
                            <>
                                <p>Необходимо сначала удалить или отвязать зависимости</p>
                                {(Object.keys(error.data.extra) as Entities[]).map((key) => {
                                    return (
                                        <li key={key} className="dependency-entities">
                                            {getEntityName(key)}: <br />
                                            {error.data.extra[key].map((entitieId: string) => (
                                                <a href={getEntityLink(key, entitieId)}>{entitieId}</a>
                                            ))}
                                        </li>
                                    );
                                })}
                            </>
                        );
                        notification.error({
                            message: <CopyErrorBtn detail={errorDescription} requestId={error.data.requestId} />,
                        });
                    }
                });
            return;
        }
        notification.warning({ message: 'Укажите причину удаления' });
    };
    if (hideDeleteBtn) {
        return null;
    }
    return (
        <>
            <Button
                onClick={() => setOpen(true)}
                style={{ backgroundColor: 'var(--color-lichi)', border: '1px solid var(--color-lichi)' }}
            >
                Удалить
            </Button>
            <Modal
                size={952}
                closeOnEsc
                destroyOnClose
                onOk={handleOk}
                isOpened={open}
                okText="Удалить"
                closeOnClickOutside
                onClose={handleClose}
                title="Удаление объекта"
                className="delete-entity-modal"
                description="Данное действие приведет к полному удалению объекта из ОРД от SberADS и ЕРИР. Он больше не будет доступен для регистрации рекламных данных. В личном кабинете вы сможете отслеживать статус удаления"
            >
                <Form layout="vertical">
                    <CauseSelect onChange={(value) => setCause(value)} value={cause} />
                    {cause === Causes.OTHER && (
                        <CauseTextArea value={customCause} onChange={(value) => setCustomCause(value)} />
                    )}
                </Form>
            </Modal>
        </>
    );
};

export default DeleteEntityBtn;
