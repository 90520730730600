import React, { FC, Suspense, useEffect, useState } from 'react';
import './styles/index.less';
import { useConfigQuery, useHeartbeatQuery } from 'shared/config/appConfig/api';
import { login } from 'shared/config/appConfig/appSlice';
import ORDLayout from 'widgets/Layout/ORDLayout';
import SandboxAlert from 'widgets/SandboxAlert/SandboxAlert';
import AppRouter from './providers/RouterProvider/AppRouter';
import { useAppDispatch, useAppSelector } from './providers/StoreProvider';

const App: FC = () => {
    useConfigQuery();
    const dispatch = useAppDispatch();
    const isAuth = useAppSelector((selector) => selector.appSlice.isAuth);
    const [polling, setPolling] = useState<number>();
    const isSandbox = window.location.origin.includes('sandbox');
    const { data, isError } = useHeartbeatQuery(
        {},
        {
            pollingInterval: polling,
        },
    );
    useEffect(() => {
        if (data && !isError) {
            if (!isAuth) {
                dispatch(login());
            }
            setPolling(data.next_heartbeat_delay / 1000000);
        }
    }, [isAuth, data, dispatch, isError]);
    return (
        <Suspense fallback="">
            {isSandbox && <SandboxAlert />}
            <ORDLayout>
                <AppRouter />
            </ORDLayout>
        </Suspense>
    );
};

export default App;
