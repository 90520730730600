import { useAppSelector } from 'app/providers/StoreProvider';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import TGIcon from 'shared/assets/TGIcon.svg';

const TGLink: FC = () => {
    const tgLink = useAppSelector((selector) => selector.appSlice.telegramInviteLink);
    return (
        <Link
            to={tgLink || `https://t.me/sberads_ord_bot?start=${btoa('not_auth')}`}
            target="_blank"
            style={{ height: '47px' }}
        >
            <TGIcon />
        </Link>
    );
};

export default TGLink;
