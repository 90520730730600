import { lazy } from 'react';
import {
    useGetCreativesListMutation,
    useGetCreativeByIdQuery,
    useGetOkvedsQuery,
    useTargetRegionsMutation,
    useRestartPublicationMutation,
    useStopPublicationMutation,
    useGetKKTUDataQuery,
    useKktuSearchMutation,
} from './model/creativesApi';
import {
    creativeSlice,
    resetDefaultItems,
    setChoosedCols,
    setColumns,
    setDefaultItems,
    addFile,
    removeFile,
    addCreativeData,
    removeCreativeData,
    setCreativeData,
} from './model/creativesSlice';
import { EridType } from './model/types';
import type {
    ICreative,
    ICreativeEntity,
    ICreativeSearchArgs,
    ICreativeSlice,
    ICreativeData,
    IKKTUSearch,
} from './model/types';

const CreativeRouter = lazy(() => import('./routes/CreativeRouter'));

export {
    CreativeRouter,
    useGetCreativesListMutation,
    useGetCreativeByIdQuery,
    useGetOkvedsQuery,
    useTargetRegionsMutation,
    useRestartPublicationMutation,
    useStopPublicationMutation,
    useGetKKTUDataQuery,
    useKktuSearchMutation,
    creativeSlice,
    resetDefaultItems,
    setChoosedCols,
    setColumns,
    setDefaultItems,
    addFile,
    removeFile,
    addCreativeData,
    removeCreativeData,
    setCreativeData,
    EridType,
    ICreative,
    ICreativeSearchArgs,
    ICreativeSlice,
    ICreativeEntity,
    ICreativeData,
    IKKTUSearch,
};
