import { EStatus, TTableSettingsListItemProps } from '@adtech/ui';
import { ColumnsType } from 'antd/lib/table';

export enum Causes {
    WRONG_DATA = 'Указаны неверные данные',
    DUPLICATE = 'Повторяющийся объект',
    OTHER = 'Другое',
}

export enum DeletingStatuses {
    NEW = 'NEW',
    REGISTERED = 'REGISTERED',
    APPROVED = 'APPROVED',
    COMPLETED = 'COMPLETED',
    FAILED = 'FAILED',
}

export const deletingStatusMap: { [Key in keyof typeof DeletingStatuses]: { title: string; icon: EStatus } } = {
    [DeletingStatuses.NEW]: {
        title: 'Заявка создана',
        icon: EStatus.end,
    },
    [DeletingStatuses.REGISTERED]: {
        title: 'Зарегистрировано в ЕРИР',
        icon: EStatus.draft,
    },
    [DeletingStatuses.APPROVED]: {
        title: 'Заявка утверждена',
        icon: EStatus.plan,
    },
    [DeletingStatuses.COMPLETED]: {
        title: 'Заявка выполнена',
        icon: EStatus.active,
    },
    [DeletingStatuses.FAILED]: {
        title: 'Заявка не выполнена',
        icon: EStatus.decline,
    },
};

export interface IDeleteRequest {
    cause: string;
    created: string;
    entity_id: string;
    entity_type: string;
    error_message?: string;
    id: string;
    status: DeletingStatuses;
    updated: string;
}

export interface IDeleteRequestList {
    data: IDeleteRequest[];
    meta: {
        total: number;
    };
}

export interface IDeleteRequestListArgs {
    limit: number;
    page: number;
    sorting: Array<string>;
}

export interface IDeleteRequestSlice {
    columns?: ColumnsType<IDeleteRequest>;
    defualtItems: TTableSettingsListItemProps[];
    choosedCols?: ColumnsType<IDeleteRequest>;
}
