import React, { FC, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import ProtectedRoute from 'shared/config/routeConfig/ProtectedRoute/ProtectedRoute';
import { routeConfig } from 'shared/config/routeConfig/routeConfig';
import AppLoad from 'shared/ui/AppLoad/AppLoad';

const AppRouter: FC = () => (
    <Routes>
        <Route element={<ProtectedRoute />}>
            {Object.values(routeConfig).map(({ element, path }) => (
                <Route key={path} path={path} element={<Suspense fallback={<AppLoad />}>{element}</Suspense>} />
            ))}
        </Route>
    </Routes>
);

export default AppRouter;
