import { Textarea } from '@adtech/ui';
import { Form } from 'antd';
import React, { FC, useEffect } from 'react';

interface ICauseTextAreaProps {
    onChange: (value: string | null) => void;
    value: string | null;
}

const CauseTextArea: FC<ICauseTextAreaProps> = (props) => {
    const { onChange, value } = props;
    useEffect(() => {
        return () => {
            onChange(null);
        };
    }, []);
    return (
        <Form.Item label="Укажите причину удаления объекта">
            <Textarea
                onChange={(e) => onChange(e.target.value)}
                value={value as string}
                placeholder="Введите причину удаления объекта"
            />
        </Form.Item>
    );
};

export default CauseTextArea;
