// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  z-index: 1;
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 16px 24px;
  background: transparent;
}
.header .extras {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 150px;
}
.logo {
  display: flex;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/widgets/Header/Header.less"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,aAAA;EACA,SAAA;EACA,mBAAA;EACA,8BAAA;EACA,WAAA;EACA,kBAAA;EACA,uBAAA;AACJ;AATA;EAWQ,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,YAAA;AACR;AAGA;EACI,aAAA;EACA,mBAAA;AADJ","sourcesContent":[".header {\n    z-index: 1;\n    display: flex;\n    gap: 16px;\n    align-items: center;\n    justify-content: space-between;\n    width: 100%;\n    padding: 16px 24px;\n    background: transparent;\n\n    .extras {\n        display: flex;\n        align-items: center;\n        justify-content: space-between;\n        width: 150px;\n    }\n}\n\n.logo {\n    display: flex;\n    align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
