import { Button, Modal, TTableSettingsListItemProps, TableSettingsList } from '@adtech/ui';
import { useAppDispatch } from 'app/providers/StoreProvider';
import React, { FC, useEffect, useState } from 'react';
import { TFunction } from 'react-i18next';
import ResetSVG from 'shared/assets/resetFiltres.svg';
import TableToolSvg from 'shared/assets/tabletool.svg';
import './TableTool.less';

interface ITableToolsProps {
    defualtItems?: TTableSettingsListItemProps[];
    setCols: (payload: TTableSettingsListItemProps[]) => {
        payload: TTableSettingsListItemProps[];
        type: string;
    };
    setItems: (payload: TTableSettingsListItemProps[]) => {
        payload: TTableSettingsListItemProps[];
        type: string;
    };
    resetDefaultItems: () => {
        type: string;
    };
    t: TFunction;
}

const TableTools: FC<ITableToolsProps> = (props) => {
    const { defualtItems, setCols, setItems, resetDefaultItems, t } = props;
    const [toolItems, setToolItems] = useState<TTableSettingsListItemProps[]>([]);
    const dispatch = useAppDispatch();
    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    useEffect(() => {
        dispatch(setCols(defualtItems as TTableSettingsListItemProps[]));
    }, [defualtItems, dispatch, setCols]);
    return (
        <>
            <Button type="dashed" prefixIcon={<TableToolSvg />} onClick={handleOpen} className="tableTool-btn" />
            <Modal
                isOpened={open}
                closeOnClickOutside
                closeOnEsc
                destroyOnClose
                onClose={() => setOpen(false)}
                title={t('ListPage.TableTool')}
                okText={t('Options.Apply')}
                onOk={() => {
                    dispatch(setItems(toolItems));
                    setOpen(false);
                }}
                footerCustomElement={
                    <Button
                        type="link"
                        prefixIcon={<ResetSVG />}
                        onClick={() => {
                            dispatch(resetDefaultItems());
                            setOpen(false);
                        }}
                    >
                        {t('Options.Reset')}
                    </Button>
                }
            >
                <TableSettingsList
                    items={defualtItems as TTableSettingsListItemProps[]}
                    onChange={(items) => setToolItems(items)}
                />
            </Modal>
        </>
    );
};

export default TableTools;
