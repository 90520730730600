import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { getFlagController } from './featureFlag';
import type { IFeatureFlag } from './featureFlag';

export interface IAppSliceSchema {
    isAuth: boolean;
    is401: boolean;
    isSelfOrg?: boolean;
    featureFlag: IFeatureFlag;
    telegramInviteLink?: string;
    contractCashe: {
        contractId: string;
        contractNumber: string;
    }[];
}

const initialState: IAppSliceSchema = {
    isAuth: false,
    is401: false,
    isSelfOrg: undefined,
    featureFlag: getFlagController(),
    telegramInviteLink: undefined,
    contractCashe: [],
};

export const appSlice = createSlice({
    name: 'appSlice',
    initialState,
    reducers: {
        login: (state) => {
            state.isAuth = true;
        },
        logout: (state) => {
            state.isAuth = false;
        },
        setIs401: (state) => {
            state.is401 = true;
        },
        setIsSelfOrg: (state, action: PayloadAction<boolean>) => {
            state.isSelfOrg = action.payload;
        },
        setTgLink: (state, action: PayloadAction<string>) => {
            state.telegramInviteLink = action.payload;
        },
        toggleFeature: (state, action: PayloadAction<keyof IFeatureFlag>) => {
            const localFlags = JSON.parse(localStorage.getItem('featureFlagController') as string);
            if (localFlags) {
                localFlags[action.payload] = !localFlags[action.payload];
                state.featureFlag[action.payload] = !state.featureFlag[action.payload];
                localStorage.setItem('featureFlagController', JSON.stringify(localFlags));
            }
        },
        addContractCasheData: (state, action: PayloadAction<{ contractId: string; contractNumber: string }>) => {
            const hasData = state.contractCashe?.find((item) => item.contractId === action.payload.contractId);
            if (!hasData) {
                state.contractCashe?.push(action.payload);
            }
        },
    },
});

export const { login, logout, setIs401, setIsSelfOrg, setTgLink, toggleFeature, addContractCasheData } =
    appSlice.actions;
