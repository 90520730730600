import { Card } from '@adtech/ui';
import { ArrowRightOutlined } from '@ant-design/icons';
import React, { FC, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useGetCreativesStatListMutation } from '../../model/creativeStatApi';

interface ICreativeStatTableProps {
    adsStatus: 'actual' | 'expired' | 'stopped';
}

const CreativeStatTable: FC<ICreativeStatTableProps> = (props) => {
    const { adsStatus } = props;
    const [list, { data, isLoading }] = useGetCreativesStatListMutation();
    useEffect(() => {
        list({
            body: {
                adsStatus,
                limit: 20,
                page: 1,
            },
        });
    }, []);
    return (
        <Card
            className="dashboard-card ads-status"
            title={adsStatus === 'actual' ? 'Необходимо передать статистику' : 'Передача статистики просрочена'}
            description={
                adsStatus === 'actual'
                    ? 'Креативы, по которым надо отчитаться в текущем периоде'
                    : 'Креативы, по которым статистика не была передана вовремя'
            }
            loading={isLoading}
        >
            <div className="card-content">
                <span className="period">{data?.meta.total || 0}</span>
                <Link to={`/creatives?adsStatus=${adsStatus}`} className="table-link">
                    <span>Таблица</span>
                    <ArrowRightOutlined />
                </Link>
            </div>
        </Card>
    );
};

export default CreativeStatTable;
