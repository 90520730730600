import { api } from 'shared/config/appConfig/api';
import {
    ICreateUserResponse,
    IUserEntity,
    TUserEntityArgs,
    TUserForm,
    IUsersListResponse,
    IGetNamespacesResponse,
    IGetNamespacesArg,
    ISavePermissionArg,
    SSOProvider,
} from './types';

const baseUrl = '/lk/v2/account';

export const userAdminApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getUsersList: builder.mutation<IUsersListResponse, TUserEntityArgs>({
            query: (arg) => ({
                url: `${baseUrl}/list`,
                method: 'POST',
                body: arg,
            }),
        }),
        getUser: builder.query<{ data: IUserEntity }, string | null>({
            query: (arg) => ({
                url: `${baseUrl}/${arg}`,
                method: 'GET',
            }),
        }),
        createLocalUser: builder.mutation<void, { id: string; type: SSOProvider }>({
            query: (arg) => ({
                url: `${baseUrl}/local`,
                method: 'POST',
                body: {
                    id: arg.id,
                    type: arg.type,
                },
            }),
        }),
        deleteLocalUser: builder.mutation<void, { id: string }>({
            query: (arg) => ({
                url: `${baseUrl}/local`,
                method: 'DELETE',
                body: {
                    id: arg.id,
                },
            }),
        }),
        createUser: builder.mutation<ICreateUserResponse, TUserForm>({
            query: (arg) => ({
                url: `${baseUrl}`,
                method: 'POST',
                body: arg,
            }),
        }),
        editUser: builder.mutation<IUserEntity, TUserForm & { id: string }>({
            query: (arg) => ({
                url: `${baseUrl}/${arg.id}`,
                method: 'PUT',
                body: arg,
            }),
        }),
        deleteUser: builder.mutation<ICreateUserResponse, { type: string; userId: string }>({
            query: (arg) => ({
                url: `${baseUrl}/${arg.userId}`,
                method: 'DELETE',
                body: { type: arg.type },
            }),
        }),
        getNameSpacesList: builder.mutation<IGetNamespacesResponse, IGetNamespacesArg>({
            query: (arg) => ({
                url: `${baseUrl}/local/namespace/list`,
                method: 'POST',
                body: arg,
            }),
        }),
        savePermission: builder.mutation<ICreateUserResponse, ISavePermissionArg>({
            query: (arg) => ({
                url: '/lk/v2/accountrole',
                method: 'POST',
                body: arg,
            }),
        }),
        deletePermission: builder.mutation<void, Omit<ISavePermissionArg, 'roles'>>({
            query: (arg) => ({
                url: '/lk/v2/accountrole',
                method: 'DELETE',
                body: arg,
            }),
        }),
    }),
});

export const {
    useGetUsersListMutation,
    useGetUserQuery,
    useCreateLocalUserMutation,
    useCreateUserMutation,
    useEditUserMutation,
    useDeleteLocalUserMutation,
    useGetNameSpacesListMutation,
    useSavePermissionMutation,
    useDeletePermissionMutation,
    useDeleteUserMutation,
} = userAdminApi;
