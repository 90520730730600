import { TRoleRequiredType } from 'entities/UserAdmin';
import React, { FC } from 'react';
import { Routes, Route } from 'react-router-dom';
import PermissionRequiredRoute from 'shared/config/routeConfig/RolesRequiredRoute/PermissionRequiredRoute';
import RolesRequiredRoute from 'shared/config/routeConfig/RolesRequiredRoute/RolesRequiredRoute';
import { platformsRouteConfig } from './PlatformsRouteConfig';

const PlatformRouter: FC = () => (
    <Routes>
        {Object.values(platformsRouteConfig).map(({ element, path, roles }) => (
            <Route element={<RolesRequiredRoute requiredRoles={roles as TRoleRequiredType} />}>
                <Route element={<PermissionRequiredRoute requiredRoles={roles as TRoleRequiredType} />}>
                    <Route key={path} path={path} element={element} />
                </Route>
            </Route>
        ))}
    </Routes>
);

export default PlatformRouter;
