import { api } from 'shared/config/appConfig/api';
import { IEntityInfo } from 'shared/lib/types';
import { ILinkedStatisticBrowseResponse, IPlatforms, IPlatformsListArgs, IPlatformsListResponse } from './types';

const baseUrl = 'lk/v2/platforms';

export const platformApi = api.injectEndpoints({
    endpoints: (builder) => ({
        createPlatform: builder.mutation<
            { data: { entity: IEntityInfo; platform: IPlatforms } },
            Omit<IPlatforms, 'organizationName'>
        >({
            query: (arg) => ({
                url: `${baseUrl}`,
                method: 'POST',
                body: arg,
            }),
        }),
        editPlatform: builder.mutation<
            { data: { entity: IEntityInfo; platform: IPlatforms } },
            Omit<IPlatforms, 'organizationName'>
        >({
            query: (arg) => ({
                url: `${baseUrl}/${arg.platformId}`,
                method: 'PUT',
                body: arg,
            }),
        }),
        getPlatformById: builder.query<{ data: { entity: IEntityInfo; platform: IPlatforms } }, { id: string | null }>({
            query: (arg) => ({
                url: `${baseUrl}/${arg.id}`,
                method: 'GET',
            }),
        }),
        getPlatformsList: builder.mutation<IPlatformsListResponse, IPlatformsListArgs>({
            query: (arg) => ({
                url: `${baseUrl}/search`,
                method: 'POST',
                body: arg,
            }),
        }),
        getLinkedStatistics: builder.mutation<ILinkedStatisticBrowseResponse, { platformId: string | null }>({
            query: (arg) => ({
                url: 'lk/v2/creative_statistics/list',
                method: 'POST',
                body: arg,
            }),
        }),
    }),
});

export const {
    useCreatePlatformMutation,
    useEditPlatformMutation,
    useGetPlatformByIdQuery,
    useGetPlatformsListMutation,
    useGetLinkedStatisticsMutation,
} = platformApi;
