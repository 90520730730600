import { TTableSettingsListItemProps } from '@adtech/ui';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IAllocationItemV1, IInvoiceItemV1, IInvoiceItemV2Entity, IInvoiceSlice } from './types';

export const initialState: IInvoiceSlice = {
    columns: undefined,
    choosedCols: undefined,
    defualtItems: [],
    detalizations: [],
    allocationsItemsV1: [],
    allocationsItemV2: [],
    platformCache: [],
    invoiceItemsVersion: 'v1',
    invoiceAmount: 0,
    invoiceCreatives: [],
    actingContract: null,
    clientRole: null,
};

export const invoiceSlice = createSlice({
    name: 'invoiceSlice',
    initialState,
    reducers: {
        setColumns: (state, action) => {
            state.columns = action.payload;
            state.choosedCols = action.payload;
        },
        setDefaultItems: (state, action: PayloadAction<TTableSettingsListItemProps[]>) => {
            state.defualtItems = action.payload;
        },
        resetDefaultItems: (state) => {
            state.defualtItems = state.defualtItems.map((item) => {
                if (item.checked === true && item.disabled !== true) {
                    return { ...item, checked: false };
                }
                return item;
            });
        },
        setChoosedCols: (state, action: PayloadAction<TTableSettingsListItemProps[]>) => {
            const colsToSet = action.payload.filter((item) => item.checked === true);
            const selectedCols: any = [];
            colsToSet.forEach((item) => {
                selectedCols.push(state.columns?.find((col) => col.key === item.id));
            });
            state.choosedCols = selectedCols;
        },
        setInvoiceItemVersion: (state, action: PayloadAction<'v1' | 'v2' | undefined>) => {
            state.invoiceItemsVersion = action.payload;
        },
        addPlatformCacheDat: (state, action: PayloadAction<{ platformId: string; platformName: string }>) => {
            state.platformCache?.push(action.payload);
        },
        setDetalization: (state, action: PayloadAction<IInvoiceItemV1[] | undefined>) => {
            state.detalizations =
                (action.payload?.map((item) => ({ ...item, amount: item.amount || '0' })) as IInvoiceItemV1[]) || [];
        },
        addDetalization: (state, action: PayloadAction<IInvoiceItemV1>) => {
            state.detalizations?.push(action.payload);
        },
        editDetalization: (
            state,
            action: PayloadAction<{
                data: IInvoiceItemV1;
                newContract?: {
                    contractId: string;
                    contractNumber: string;
                };
                _contractId: string | null;
                _cid: string | null;
            }>,
        ) => {
            const editId = state.detalizations?.findIndex((item) => {
                if (item.contractId && action.payload._contractId) {
                    return item.contractId === action.payload._contractId;
                }
                if (item.cid && action.payload._cid) {
                    return item.cid === action.payload._cid;
                }
                return -1;
            });
            // const newContract = appStore.getState().appSlice.contractCashe?.find((item) => {
            //     if (item.contractId && action.payload.data.contractId) {
            //         return item.contractId === action.payload.data.contractId;
            //     }
            //     return undefined;
            // });
            if (editId !== -1) {
                (state.detalizations as IInvoiceItemV1[])[editId as number] = { ...action.payload.data };
                if (state.invoiceItemsVersion === 'v1') {
                    state.allocationsItemsV1 = state.allocationsItemsV1.map((item) => {
                        // если изначально в форме был договор
                        if (
                            item.contractId &&
                            action.payload._contractId &&
                            item.contractId === action.payload._contractId
                        ) {
                            // если при редактировании передан cid
                            if (action.payload.data.cid) {
                                return {
                                    ...item,
                                    contractId: null,
                                    contractNumber: null,
                                    cid: action.payload.data.cid,
                                };
                            }
                            // если передан contractId
                            return {
                                ...item,
                                contractId: action.payload.newContract?.contractId as string,
                                contractNumber: action.payload.newContract?.contractNumber as string,
                                cid: null,
                            };
                        }
                        // если изначально в форме был cid
                        if (item.cid && action.payload._cid && item.cid === action.payload._cid) {
                            // если при редактировании был передан contractId
                            if (action.payload.data.contractId) {
                                return {
                                    ...item,
                                    contractId: action.payload.newContract?.contractId as string,
                                    contractNumber: action.payload.newContract?.contractNumber as string,
                                    cid: null,
                                };
                            }
                            // если передан cid
                            return {
                                ...item,
                                contractId: null,
                                contractNumber: null,
                                cid: action.payload.data.cid,
                            };
                        }
                        return item;
                    });
                }
                if (state.invoiceItemsVersion === 'v2') {
                    state.allocationsItemV2 = state.allocationsItemV2.map((item) => {
                        // если изначально в форме был договор
                        if (
                            item.invoiceCreatives.contractId &&
                            action.payload._contractId &&
                            item.invoiceCreatives.contractId === action.payload._contractId
                        ) {
                            if (action.payload.data.cid) {
                                return {
                                    ...item,
                                    contractName: null,
                                    invoiceCreatives: {
                                        ...item.invoiceCreatives,
                                        contractId: null,
                                        cid: action.payload.data.cid,
                                    },
                                };
                            }
                            return {
                                ...item,
                                contractName: action.payload.newContract?.contractNumber,
                                invoiceCreatives: {
                                    ...item.invoiceCreatives,
                                    contractId: action.payload.newContract?.contractId,
                                    cid: null,
                                },
                            };
                        }
                        // если изначально в форме был cid
                        if (
                            item.invoiceCreatives.cid &&
                            action.payload._cid &&
                            item.invoiceCreatives.cid === action.payload._cid
                        ) {
                            if (action.payload.data.contractId) {
                                return {
                                    ...item,
                                    contractName: action.payload.newContract?.contractNumber,
                                    invoiceCreatives: {
                                        ...item.invoiceCreatives,
                                        contractId: action.payload.newContract?.contractId,
                                        cid: null,
                                    },
                                };
                            }
                            return {
                                ...item,
                                invoiceCreatives: {
                                    ...item.invoiceCreatives,
                                    cid: action.payload.data.cid,
                                    contractId: null,
                                },
                            };
                        }
                        return item;
                    });
                }
            }
        },
        setAllocationItemV1: (state, action: PayloadAction<IAllocationItemV1[] | undefined>) => {
            state.allocationsItemsV1 = action.payload as IAllocationItemV1[];
        },
        addAllocationItemV1: (state, action: PayloadAction<IAllocationItemV1 | undefined>) => {
            state.allocationsItemsV1.push(action.payload as IAllocationItemV1);
            state.invoiceItemsVersion = 'v1';
        },
        editAllocationItemV1: (
            state,
            action: PayloadAction<{ data: IAllocationItemV1; creativeId?: string; platformId?: string | null }>,
        ) => {
            const { data, creativeId, platformId } = action.payload;
            const allocationItemIndex = state.allocationsItemsV1.findIndex(
                (item) => item.creativeId === creativeId && item.item.platformId === platformId,
            );
            if (allocationItemIndex !== -1) {
                state.allocationsItemsV1[allocationItemIndex] = data;
            }
        },
        setAllocationItemV2: (state, action: PayloadAction<IInvoiceItemV2Entity[] | undefined>) => {
            state.allocationsItemV2 = action.payload as IInvoiceItemV2Entity[];
            state.invoiceItemsVersion = 'v2';
        },
        addAllocationItemV2: (
            state,
            action: PayloadAction<{
                creativeId?: string | null;
                cid?: string | null;
                marker?: string | null;
                contractId?: string | null;
                contractNumber?: string | null;
                isEditing?: boolean;
                creativeForEdit?: string | null;
            }>,
        ) => {
            const targetContract = state.allocationsItemV2.findIndex((item) => {
                if (action.payload.contractId) {
                    return item.invoiceCreatives.contractId === action.payload.contractId;
                }
                if (action.payload.cid) {
                    return item.invoiceCreatives.cid === action.payload.cid;
                }
                return -1;
            });
            if (targetContract !== -1) {
                const existingCreative = state.allocationsItemV2[targetContract].invoiceCreatives.creatives.findIndex(
                    (item) => item.creativeId === action.payload.creativeId,
                );
                const editingCreative = state.allocationsItemV2[targetContract].invoiceCreatives.creatives.findIndex(
                    (item) => item.creativeId === action.payload.creativeForEdit,
                );
                if (existingCreative === -1 && !action.payload.isEditing) {
                    state.allocationsItemV2[targetContract].invoiceCreatives.creatives.push({
                        creativeId: action.payload.creativeId,
                        marker: action.payload.marker,
                    });
                }
                if (editingCreative !== -1 && action.payload.isEditing) {
                    state.allocationsItemV2[targetContract].invoiceCreatives.creatives[editingCreative] = {
                        creativeId: action.payload.creativeId,
                        marker: action.payload.marker,
                    };
                }
            } else {
                state.allocationsItemV2.push({
                    contractName: action.payload.contractNumber,
                    invoiceCreatives: {
                        contractId: action.payload.contractId || null,
                        cid: action.payload.cid || null,
                        creatives: [
                            {
                                creativeId: action.payload.creativeId,
                                marker: action.payload.marker,
                            },
                        ],
                    },
                });
            }
        },
        setInvoiceAmount: (state, action: PayloadAction<number>) => {
            state.invoiceAmount = action.payload;
        },
        setActingContract: (state, action: PayloadAction<string | null>) => {
            state.actingContract = action.payload;
        },
        setClientRole: (state, action: PayloadAction<string | null>) => {
            state.clientRole = action.payload;
        },
        deleteAllocation: (state) => {
            state.allocationsItemsV1 = [];
            state.allocationsItemV2 = [];
        },
    },
});

export const {
    setColumns,
    setChoosedCols,
    setDefaultItems,
    resetDefaultItems,
    setDetalization,
    addDetalization,
    setInvoiceItemVersion,
    setAllocationItemV1,
    addAllocationItemV1,
    setAllocationItemV2,
    addPlatformCacheDat,
    setInvoiceAmount,
    addAllocationItemV2,
    setActingContract,
    setClientRole,
    editDetalization,
    editAllocationItemV1,
    deleteAllocation,
} = invoiceSlice.actions;
