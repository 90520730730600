import { Layout } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { FC, ReactNode } from 'react';
import useBanner from 'shared/lib/hooks/useBanner';
import Header from 'widgets/Header/Header';
import './Layout.less';
import Sider from 'widgets/Sider/Sider';

interface IORDLayoutProps {
    children: ReactNode;
}

const ORDLayout: FC<IORDLayoutProps> = (props) => {
    const { children } = props;
    useBanner();
    return (
        <Layout className="layout">
            <Header />
            <Layout className="layout" hasSider>
                <Sider />
                <Content>
                    <div className="page-wrapper">{children}</div>
                </Content>
            </Layout>
            <div className="shape1" />
            <div className="shape2" />
        </Layout>
    );
};

export default ORDLayout;
