import { TTableSettingsListItemProps } from '@adtech/ui';
import { ColumnsType } from 'antd/lib/table';
import React, { ReactNode } from 'react';
import { IEntityInfo } from 'shared/lib/types';

export interface ICreativeStatistic {
    creativeId?: string | null;
    platformId?: string | null;
    impsPlan?: number | string | null;
    impsFact?: number | string | null;
    dateStartFact?: string | null;
    dateEndFact?: string | null;
    dateStartPlan?: string | null;
    dateEndPlan?: string | null;
    amountPerShow?: number | string | null;
    amount?: string | number | string | null;
    isVat?: boolean | null;
    id?: string;
    campaignType?: string | null;
}

export interface ICreativeStatisticBrowse {
    status: string[] | string | null;
    marker: string[] | string | null;
    date: string | null;
    planStartDate: string | null;
    factStartDate: string | null;
    planEndDate: string | null;
    factEndDate: string | null;
    created: string | null;
    registred: string | null;
    sorting?: string[] | null;
    page?: number;
    limit?: number;
    cursor?: string | null;
}

export interface IMeta {
    page: number;
    limit: number;
    total: number;
    next: string;
}

export interface ICreativeStatisticBrowseData {
    creativeStatistic: ICreativeStatistic;
    entity: IEntityInfo;
    marker: string;
    platformName: string;
}

export interface ICreativeStatisticBrowseResponse {
    data: ICreativeStatisticBrowseData[];
    meta: IMeta;
}

export interface ICreativeStatisticFormResponse {
    id: string;
    einfo: IEntityInfo;
}

export interface IPlatformsListArgs {
    organizationId?: string | null;
    type?: string | null;
    name?: string | null;
    createDate?: string | null;
    limit?: number | null;
    page?: number;
    sorting?: string[] | null;
    status?: string | null;
    platformId?: string | null;
}

export interface IPlatforms {
    organizationName?: string | null;
    organizationId: string | null;
    platformId: string | null;
    type: string | null;
    isOwned: boolean;
    name: string | null;
    url: string | null;
    externalId: string | null;
}

export interface IPlatformsEntity {
    entity: IEntityInfo;
    platform: IPlatforms;
}

export interface IPlatformsListResponse {
    meta: IMeta;
    data: IPlatformsEntity[];
}

export type TViewStatBatchConfig = Record<keyof Omit<ICreativeStatistic, 'id'>, { label: string; tooltip: ReactNode }>;

export interface IViewStatSlice {
    columns?: ColumnsType<ICreativeStatisticBrowseData>;
    defualtItems: TTableSettingsListItemProps[];
    choosedCols?: ColumnsType<ICreativeStatisticBrowseData>;
    batchConfigLabelMap: TViewStatBatchConfig;
}

export const batchConfigLabelMap: TViewStatBatchConfig = {
    creativeId: {
        label: 'Маркер (erid креатива)',
        tooltip: <div>Подсказка</div>,
    },
    platformId: {
        label: 'Идентификатор площадки (UUID)',
        tooltip: <div>Подсказка</div>,
    },
    dateStartPlan: {
        label: 'Плановая дата начала показов (дата)',
        tooltip: <div>Подсказка</div>,
    },
    dateStartFact: {
        label: 'Фактическая дата начала показов (дата)',
        tooltip: <div>Подсказка</div>,
    },
    dateEndPlan: {
        label: 'Плановая дата окончания показов (дата)',
        tooltip: <div>Подсказка</div>,
    },
    dateEndFact: {
        label: 'Фактическая дата окончания показов (дата)',
        tooltip: <div>Подсказка</div>,
    },
    impsPlan: {
        label: 'Фактическое количество показов (числовой)',
        tooltip: <div>Подсказка</div>,
    },
    impsFact: {
        label: 'Количество показов по акту (числовой)',
        tooltip: <div>Подсказка</div>,
    },
    amount: {
        label: 'Суммарная стоимость оказанных услуг, ₽ (денежный)',
        tooltip: <div>Подсказка</div>,
    },
    amountPerShow: {
        label: 'Стоимость единицы оказания услуг, ₽ (денежный)',
        tooltip: <div>Подсказка</div>,
    },
    isVat: {
        label: 'Признак "с НДС" (строковый)',
        tooltip: <div>Подсказка</div>,
    },
    campaignType: {
        label: '',
        tooltip: '',
    },
};
