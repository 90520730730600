import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { Entities } from 'shared/lib/types';
import { IErirMessages } from '../../../../model/creativesStatTypes';
import MessageModal from './MessageModal';

export const erirMessagesTableCols = (
    setMessages: React.Dispatch<React.SetStateAction<IErirMessages[]>>,
): ColumnsType<IErirMessages> => {
    return [
        {
            title: 'Тип и название',
            className: 'name',
            render: (value, row) => {
                switch (row.entityType) {
                    case Entities.ORG:
                        return <Link to={`/organizations/${row.entityId}`}>Организация {row.mainAttribute}</Link>;
                    case Entities.PLA:
                        return <Link to={`/platforms/${row.entityId}`}>Площадка {row.mainAttribute}</Link>;
                    case Entities.CON:
                        return <Link to={`/contracts/${row.entityId}`}>Договор {row.mainAttribute}</Link>;
                    case Entities.CRE:
                        return <Link to={`/creatives/${row.entityId}`}>Креатив {row.mainAttribute}</Link>;
                    case Entities.INV:
                        return <Link to={`/invoices/${row.entityId}`}>Акт {row.mainAttribute}</Link>;
                    case Entities.STA:
                        return (
                            <Link to={`/creative-statistics/${row.entityId}`}>
                                Статистика показов {row.mainAttribute}
                            </Link>
                        );
                    case Entities.ICR:
                        return (
                            <Link to={`/invoices/${row.attributes}`}>
                                Упрощенная статистика (Акт {row.mainAttribute})
                            </Link>
                        );
                    default:
                        return '–';
                }
            },
        },
        {
            title: '',
            className: 'status',
            render: (value, row) => (row.status === 'NEW' ? <div className="status"> Новое</div> : ''),
        },
        {
            title: 'Заголовок сообщения',
            className: 'title',
            render: (value, row) => (
                <MessageModal
                    title={row.title}
                    message={row.message}
                    messageId={row.messageId}
                    status={row.status}
                    setMessages={setMessages}
                />
            ),
        },
        {
            title: 'Дата получения',
            className: 'created',
            render: (value, row) => moment(row.createdAt).format('YYYY-MM-DD'),
        },
        {
            title: 'Дата отправки',
            className: 'date',
            render: (value, row) => moment(row.receivedAt).format('YYYY-MM-DD'),
        },
    ];
};

export const erirMessagesTableColsWithoutStatus = (
    setMessages: React.Dispatch<React.SetStateAction<IErirMessages[]>>,
): ColumnsType<IErirMessages> => {
    return [
        {
            title: 'Тип и название',
            className: 'name',
            render: (value, row) => {
                switch (row.entityType) {
                    case Entities.ORG:
                        return <Link to={`/organizations/${row.entityId}`}>Организация {row.mainAttribute}</Link>;
                    case Entities.PLA:
                        return <Link to={`/platforms/${row.entityId}`}>Площадка {row.mainAttribute}</Link>;
                    case Entities.CON:
                        return <Link to={`/contracts/${row.entityId}`}>Договор {row.mainAttribute}</Link>;
                    case Entities.CRE:
                        return <Link to={`/creatives/${row.entityId}`}>Креатив {row.mainAttribute}</Link>;
                    case Entities.INV:
                        return <Link to={`/invoices/${row.entityId}`}>Акт {row.mainAttribute}</Link>;
                    case Entities.STA:
                        return (
                            <Link to={`/creative-statistics/${row.entityId}`}>
                                Статистика показов {row.mainAttribute}
                            </Link>
                        );
                    case Entities.ICR:
                        return (
                            <Link to={`/invoices/${row.attributes}`}>
                                Упрощенная статистика (Акт {row.mainAttribute})
                            </Link>
                        );
                    default:
                        return '–';
                }
            },
        },
        {
            title: 'Заголовок сообщения',
            className: 'title',
            render: (value, row) => (
                <MessageModal
                    title={row.title}
                    message={row.message}
                    messageId={row.messageId}
                    status={row.status}
                    setMessages={setMessages}
                />
            ),
        },
        {
            title: 'Дата получения',
            className: 'created',
            render: (value, row) => moment(row.createdAt).format('YYYY-MM-DD'),
        },
        {
            title: 'Дата отправки',
            className: 'date',
            render: (value, row) => moment(row.receivedAt).format('YYYY-MM-DD'),
        },
    ];
};
