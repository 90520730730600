import { IGetPlatformsByCintractIDResponse } from 'entities/Invoices/model/types';
import { api } from 'shared/config/appConfig/api';
import {
    ICreativeStatistic,
    ICreativeStatisticBrowse,
    ICreativeStatisticBrowseData,
    ICreativeStatisticBrowseResponse,
    ICreativeStatisticFormResponse,
    IPlatformsListArgs,
    IPlatformsListResponse,
} from './types';

export const viewStatisticsApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getCreativeStatistics: builder.mutation<ICreativeStatisticBrowseResponse, ICreativeStatisticBrowse>({
            query: (arg) => ({
                url: '/lk/v2/creative_statistics/list',
                method: 'POST',
                body: arg,
            }),
        }),
        getCreativeStatisticsById: builder.query<{ data: ICreativeStatisticBrowseData }, string | undefined>({
            query: (arg) => ({
                url: `/lk/v2/creative_statistics/${arg}`,
                method: 'GET',
            }),
        }),
        postCreativeStatistics: builder.mutation<
            { data: ICreativeStatisticFormResponse },
            Omit<ICreativeStatistic, 'id'>
        >({
            query: (arg) => ({
                url: '/lk/v2/creative_statistics',
                method: 'POST',
                body: arg,
            }),
        }),
        putCreativeStatistics: builder.mutation<{ data: ICreativeStatisticFormResponse }, ICreativeStatistic>({
            query: (arg) => ({
                url: `/lk/v2/creative_statistics/${arg.id}`,
                method: 'PUT',
                body: arg,
            }),
        }),
        getPlatformsByCretiveId: builder.mutation<IPlatformsListResponse, IPlatformsListArgs>({
            query: (arg) => ({
                url: `/lk/v2/platforms/search`,
                method: 'POST',
                body: arg,
            }),
        }),
    }),
});

export const {
    useGetCreativeStatisticsMutation,
    useGetCreativeStatisticsByIdQuery,
    usePostCreativeStatisticsMutation,
    usePutCreativeStatisticsMutation,
    useGetPlatformsByCretiveIdMutation,
} = viewStatisticsApi;
