import { Modal } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/providers/StoreProvider';
import { useGetSelfOrganizationQuery } from 'entities/Organization';
import { setUserData } from 'entities/UserAdmin';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { setIsSelfOrg, setTgLink } from 'shared/config/appConfig/appSlice';
import AppLoad from 'shared/ui/AppLoad/AppLoad';
import { useConfigQuery, useUdataQuery } from '../../appConfig/api';
import { IUData401 } from './types';

const ProtectedRoute: FC = () => {
    const dispatch = useAppDispatch();
    const isAuth = useAppSelector((selector) => selector.appSlice.isAuth);
    const { data: config } = useConfigQuery();
    const {
        data: uData,
        error,
        isLoading,
    } = useUdataQuery(
        {},
        {
            skip: !isAuth,
        },
    );
    const skipQueryCondition = !uData || !uData.permissions.find((perm) => perm.role === 'BusinessUser');
    const { refetch: selfOrgRefetch } = useGetSelfOrganizationQuery({}, { skip: skipQueryCondition });
    const uDataError = (error as IUData401) || undefined;
    const facts = useMemo(
        () =>
            uDataError?.data && uDataError?.data?.missingFacts
                ? uDataError.data.missingFacts.map((item) => item.factTypeId)
                : [],
        [uDataError],
    );
    const getRedirectUrl = useCallback(
        (ifId?: string) => {
            switch (ifId) {
                case 'lk-dev':
                    // eslint-disable-next-line max-len
                    return `https://website-dev.ord-lab.dev/profile?ifId=${config?.ifId}&returnUrl=${window.location.href}`;
                case 'lk-test':
                    // eslint-disable-next-line max-len
                    return `https://website-test.ord-lab.dev/profile?ifId=${config?.ifId}&returnUrl=${window.location.href}`;
                case 'lk-sandbox':
                    return `https://ord-lab.ru/profile?ifId=${config?.ifId}&returnUrl=${window.location.href}`;
                case 'lk-prod':
                    return `https://ord-lab.ru/profile?ifId=${config?.ifId}&returnUrl=${window.location.href}`;
                default:
                    return 'https://ord-lab.ru/profile';
            }
        },
        [config?.ifId],
    );
    useEffect(() => {
        if (facts.length > 0) {
            Modal.confirm({
                title: 'Необходимо принятие согласия(-ий)',
                content: 'Вы будете перенаправлены на сайт ОРД для принятия согласий',
                onOk: () => window.location.replace(getRedirectUrl(config?.ifId as string)),
                cancelButtonProps: { style: { display: 'none' } },
            });
        }
    }, [config?.ifId, facts.length, getRedirectUrl]);
    useEffect(() => {
        if (uData && uData.permissions.find((perm) => perm.role === 'BusinessUser')) {
            selfOrgRefetch()
                .unwrap()
                .then((selfOrgData) => {
                    dispatch(setIsSelfOrg(Boolean(selfOrgData?.orgnisation)));
                });
        }
    }, [dispatch, selfOrgRefetch, uData]);
    useEffect(() => {
        if (uData) {
            dispatch(setUserData(uData.permissions));
            dispatch(setTgLink(uData.telegramInviteLink));
        }
    }, [uData, dispatch]);
    if (isLoading) {
        return <AppLoad />;
    }
    return <Outlet />;
};

export default ProtectedRoute;
