import { TTableSettingsListItemProps } from '@adtech/ui';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ICreativeData, ICreativeSlice, batchConfigLabelMap } from './types';

export const initialState: ICreativeSlice = {
    columns: undefined,
    choosedCols: undefined,
    defualtItems: [],
    fileCounter: 0,
    creativeData: [],
    files: [],
    targetUrls: [],
    batchConfigLabelMap,
};

export const creativeSlice = createSlice({
    name: 'creativeSlice',
    initialState,
    reducers: {
        setColumns: (state, action) => {
            state.columns = action.payload;
            state.choosedCols = action.payload;
        },
        setDefaultItems: (state, action: PayloadAction<TTableSettingsListItemProps[]>) => {
            state.defualtItems = action.payload;
        },
        resetDefaultItems: (state) => {
            state.defualtItems = state.defualtItems.map((item) => {
                if (item.checked === true && item.disabled !== true) {
                    return { ...item, checked: false };
                }
                return item;
            });
        },
        setChoosedCols: (state, action: PayloadAction<TTableSettingsListItemProps[]>) => {
            const colsToSet = action.payload.filter((item) => item.checked === true);
            const selectedCols: any = [];
            colsToSet.forEach((item) => {
                selectedCols.push(state.columns?.find((col) => col.key === item.id));
            });
            state.choosedCols = selectedCols;
        },
        setCreativeData: (state, action: PayloadAction<ICreativeData[]>) => {
            state.creativeData = action.payload;
        },
        addCreativeData: (state, action: PayloadAction<ICreativeData>) => {
            state.creativeData.push(action.payload);
        },
        removeCreativeData: (state, action: PayloadAction<string>) => {
            const fileId = state.creativeData.find((item) => item.externalId === action.payload)?.fileId;
            state.files = state.files.filter((item) => item.id !== fileId);
            state.creativeData = state.creativeData.filter((item) => item.externalId !== action.payload);
        },
        addFile: (state, action: PayloadAction<File | undefined>) => {
            state.files.push({
                id: `0${state.fileCounter}`,
                file: action.payload,
            });
            state.fileCounter += 1;
        },
        removeFile: (state, action: PayloadAction<string>) => {
            state.files = state.files.filter((item) => item.id !== action.payload);
        },
    },
});

export const {
    setColumns,
    setChoosedCols,
    setDefaultItems,
    resetDefaultItems,
    addFile,
    removeFile,
    addCreativeData,
    removeCreativeData,
    setCreativeData,
} = creativeSlice.actions;
