import { Typography } from 'antd';
import React, { FC, ReactNode } from 'react';

interface ICopyErrorBtnProps {
    requestId: string;
    detail: string | ReactNode;
}

const CopyErrorBtn: FC<ICopyErrorBtnProps> = ({ requestId, detail }) => {
    return (
        <>
            <div>{detail}</div>
            <Typography.Paragraph copyable={{ text: requestId }}>Скопировать код ошибки</Typography.Paragraph>
        </>
    );
};

export default CopyErrorBtn;
