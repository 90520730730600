import { lazy } from 'react';
import {
    useGetCreativeStatisticsMutation,
    useGetCreativeStatisticsByIdQuery,
    usePostCreativeStatisticsMutation,
    usePutCreativeStatisticsMutation,
    useGetPlatformsByCretiveIdMutation,
    viewStatisticsApi,
} from './model/viewStatisticsApi';
import {
    setColumns,
    setDefaultItems,
    resetDefaultItems,
    setChoosedCols,
    viewStatisticsSlice,
} from './model/viewStatisticsSlice';
import type {
    ICreativeStatistic,
    ICreativeStatisticBrowse,
    ICreativeStatisticBrowseData,
    ICreativeStatisticBrowseResponse,
    ICreativeStatisticFormResponse,
    IMeta,
    IViewStatSlice,
} from './model/types';

const WiewStatisticsRouter = lazy(() => import('./Routes/ViewstatisticsRouter'));

export {
    WiewStatisticsRouter,
    setColumns,
    setDefaultItems,
    resetDefaultItems,
    setChoosedCols,
    viewStatisticsSlice,
    viewStatisticsApi,
    ICreativeStatistic,
    ICreativeStatisticBrowse,
    ICreativeStatisticBrowseData,
    ICreativeStatisticBrowseResponse,
    ICreativeStatisticFormResponse,
    IMeta,
    IViewStatSlice,
    useGetCreativeStatisticsMutation,
    useGetCreativeStatisticsByIdQuery,
    usePostCreativeStatisticsMutation,
    usePutCreativeStatisticsMutation,
    useGetPlatformsByCretiveIdMutation,
};
