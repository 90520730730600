import { Card, PageHeader } from '@adtech/ui';
import { useAppSelector } from 'app/providers/StoreProvider';
import { UserRoles } from 'entities/UserAdmin';
import moment from 'moment';
import React, { FC } from 'react';
import './MainPage.less';
import CreativesStat from './CreativesStat/CreativesStat';
import DataQualityTables from './DataQuality/DataQualityTables';
import KnowledgeBase from './KnowledgeBase/KnowledgeBase';

const MainPage: FC = () => {
    const endOfMonth = moment().subtract(1, 'month').endOf('month').add(30, 'days');
    const currentDate = moment();
    const period = endOfMonth.diff(currentDate, 'days') + 1;
    const roles = useAppSelector((selector) => selector.userSlice.role.map((item) => item.role));
    const isUserAdmin = roles.includes(UserRoles.ClientAdmin);
    const humanizeDays = () => {
        if (period > 10 && [11, 12, 13, 14].includes(period % 100)) return 'дней';
        const lastNum = period % 10;
        if (lastNum === 1) return 'день';
        if ([2, 3, 4].includes(lastNum)) return 'дня';
        if ([5, 6, 7, 8, 9, 0].includes(lastNum)) return 'дней';
        return 'день';
    };
    const getPeriodColor = () => {
        switch (true) {
            case period > 20:
                return 'green';
            case period >= 10 && period <= 20:
                return 'yellow';
            case period < 10:
                return 'red';
            default:
                return 'black';
        }
    };
    if (roles.length === 0) {
        return null;
    }
    return (
        <>
            <PageHeader title="Панель управления" extra={[]} style={{ marginBottom: '24px' }} />
            {!isUserAdmin && (
                <>
                    <div className="card-container-main-page">
                        <Card
                            className="dashboard-card"
                            title="До конца передачи статистики"
                            description={`за ${moment().subtract(1, 'month').format('MMMM')}`}
                        >
                            <span className={`period ${getPeriodColor()}`}>
                                {period} {humanizeDays()}
                            </span>
                        </Card>
                        <CreativesStat />
                    </div>
                    <DataQualityTables />
                    <KnowledgeBase />
                </>
            )}
        </>
    );
};

export default MainPage;
