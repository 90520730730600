import { useAppSelector } from 'app/providers/StoreProvider';
import { TRoleRequiredType } from 'entities/UserAdmin';
import { transformPermissionData } from 'entities/UserAdmin/model/lib';
import React, { FC, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
// import { transformPermissionData } from 'shared/lib/helpers';
import AppLoad from 'shared/ui/AppLoad/AppLoad';

interface IRolesRequiredRouteProps {
    requiredRoles: TRoleRequiredType;
}

const RolesRequiredRoute: FC<IRolesRequiredRouteProps> = ({ requiredRoles }) => {
    const userPermData = useAppSelector((selector) => selector.userSlice.role);
    const navigate = useNavigate();
    useEffect(() => {
        if (userPermData.length !== 0) {
            const { roles } = transformPermissionData(userPermData);
            const filteredRoleArray = requiredRoles.roles.filter((requiredRole) => roles.includes(requiredRole));
            if (filteredRoleArray.length === 0) {
                navigate('/forbidden-page');
            }
        }
    }, [navigate, requiredRoles, userPermData]);
    if (!userPermData) {
        return <AppLoad />;
    }
    if (userPermData) {
        return <Outlet />;
    }
    return null;
};

export default RolesRequiredRoute;
