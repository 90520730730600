import React, { FC } from 'react';
import CreativeStatTable from './CreativeStatСards';

const CreativesStat: FC = () => {
    return (
        <>
            <CreativeStatTable adsStatus="actual" />
            <CreativeStatTable adsStatus="expired" />
        </>
    );
};

export default CreativesStat;
