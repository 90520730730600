import { api } from 'shared/config/appConfig/api';
import {
    ICreativeStatArgs,
    ICreativeStatBrowseResponse,
    IErirErrors,
    IErirErrorsSearchArgs,
    IErirMessages,
    IErirMessagesSearchArgs,
    IMeta,
} from './creativesStatTypes';

const creativeStatApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getCreativesStatList: builder.mutation<ICreativeStatBrowseResponse, { body: ICreativeStatArgs }>({
            query: (arg) => ({
                url: `lk/v1/creatives/list`,
                method: 'POST',
                body: arg.body,
            }),
        }),
        getErirMessages: builder.mutation<{ data: IErirMessages[]; meta: IMeta }, IErirMessagesSearchArgs>({
            query: (arg) => ({
                url: 'lk/v1/dashboard/messages/search',
                method: 'POST',
                body: arg,
            }),
        }),
        getErirErrors: builder.mutation<{ data: IErirErrors[]; meta: IMeta }, IErirErrorsSearchArgs>({
            query: (arg) => ({
                url: '/lk/v1/dashboard/errors/search',
                method: 'POST',
                body: arg,
            }),
        }),
        readMessage: builder.mutation<{ data: string }, { messageId: string }>({
            query: (arg) => ({
                url: '/lk/v1/dashboard/messages',
                method: 'POST',
                body: { messageId: arg.messageId },
            }),
        }),
    }),
});

export const {
    useGetCreativesStatListMutation,
    useGetErirMessagesMutation,
    useGetErirErrorsMutation,
    useReadMessageMutation,
} = creativeStatApi;
