// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SBA-table#erir-table .SBA-zerostate {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.SBA-table#erir-table th {
  white-space: normal;
}
.SBA-table#erir-table td {
  font-size: 12px;
}
.SBA-table#erir-table td.name {
  max-width: 100px;
  white-space: break-spaces;
}
.SBA-table#erir-table td.name a {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.SBA-table#erir-table td.SBA-table-cell.status {
  max-width: 30px;
}
.SBA-table#erir-table td.SBA-table-cell.status div {
  max-width: max-content;
  display: flex;
  padding: 1px 8px 2px 8px;
  align-items: flex-start;
  border-radius: 24px;
  border: 1px solid var(--color-arctic-ads--active);
  color: var(--Blue-Carbon, #333F48);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.3px;
}
.SBA-table#erir-table td.code {
  max-width: 50px;
  font-weight: 600;
}
.SBA-table#erir-table td.created {
  max-width: 60px;
}
.SBA-table#erir-table td.date {
  max-width: 50px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/MainPage/ui/DataQuality/Tables/ErrorsTable/ErirErrorsTable.less"],"names":[],"mappings":"AAAA;EAEQ,YAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;AAAR;AALA;EAOS,mBAAA;AACT;AARA;EAQS,eAAA;AAGT;AAXA;EAUQ,gBAAA;EACA,yBAAA;AAIR;AAfA;EAaY,oBAAA;EACA,4BAAA;EACA,qBAAA;EACA,gBAAA;AAKZ;AArBA;EAoBQ,eAAA;AAIR;AAxBA;EAsBY,sBAAA;EACA,aAAA;EACA,wBAAA;EACA,uBAAA;EACA,mBAAA;EACA,iDAAA;EACA,kCAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,sBAAA;AAKZ;AAtCA;EAqCQ,eAAA;EACA,gBAAA;AAIR;AA1CA;EAwCiB,eAAA;AAKjB;AA7CA;EAyCc,eAAA;AAOd","sourcesContent":[".SBA-table#erir-table {\n    .SBA-zerostate {\n        margin: auto;\n        display: flex;\n        flex-direction: column;\n        align-items: center;\n    }\n    th { white-space: normal; }\n    td { font-size: 12px; }\n    td.name { \n        max-width: 100px;\n        white-space: break-spaces;\n        a {\n            display: -webkit-box;\n            -webkit-box-orient: vertical;\n            -webkit-line-clamp: 2;\n            overflow: hidden;\n        }\n    };\n    td.SBA-table-cell.status {\n        max-width: 30px;\n        div {\n            max-width: max-content;\n            display: flex;\n            padding: 1px 8px 2px 8px;\n            align-items: flex-start;\n            border-radius: 24px;\n            border: 1px solid var(--color-arctic-ads--active);\n            color: var(--Blue-Carbon, #333F48);\n            font-size: 10px;\n            font-style: normal;\n            font-weight: 400;\n            line-height: normal;\n            letter-spacing: -0.3px;\n        }\n    }\n    td.code { \n        max-width: 50px;\n        font-weight: 600;\n     };\n    td.created { max-width: 60px; };\n    td.date { max-width: 50px; };\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
