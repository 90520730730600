import { IconDataExport, IconStatistics, IconTrash } from '@adtech/ui';
import { UserRoles, TRoleRequiredType } from 'entities/UserAdmin';
import React, { ReactNode } from 'react';
import ContractLogo from 'shared/assets/Contracts.svg';
import CrativeLogo from 'shared/assets/Creatives.svg';
import InvoiceLogo from 'shared/assets/Invoices.svg';
import KeysLogo from 'shared/assets/Key.svg';
import ORGLogo from 'shared/assets/ORG.svg';
import ReportsLogo from 'shared/assets/Reports.svg';
import Platforms from 'shared/assets/platforms.svg';

export interface IMenuItems {
    label: string;
    key: string;
    icon: ReactNode;
    to: string;
    roles?: TRoleRequiredType;
    className?: string;
}

export const menuItems: IMenuItems[] = [
    {
        label: 'Entites.Organizations',
        key: 'org',
        icon: <ORGLogo />,
        to: '/organizations',
        roles: {
            roles: [UserRoles.BusinessUser, UserRoles.BusinessReader],
            entities: 'ORG',
        },
    },
    {
        label: 'Entites.Platforms',
        key: 'pla',
        icon: <Platforms />,
        className: 'pla',
        to: '/platforms',
        roles: {
            roles: [UserRoles.BusinessUser, UserRoles.BusinessReader],
            //   entities: 'PLA',
        },
    },
    {
        label: 'Entites.Contracts',
        key: 'con',
        icon: <ContractLogo />,
        to: '/contracts',
        roles: {
            roles: [UserRoles.BusinessUser, UserRoles.BusinessReader],
            entities: 'CON',
        },
    },
    {
        label: 'Entites.Creatives',
        key: 'cre',
        icon: <CrativeLogo />,
        to: '/creatives',
        roles: {
            roles: [UserRoles.BusinessUser, UserRoles.BusinessReader],
            entities: 'CRE',
        },
    },
    {
        label: 'Entites.Invoices',
        key: 'inv',
        icon: <InvoiceLogo />,
        to: '/invoices',
        roles: {
            roles: [UserRoles.BusinessUser, UserRoles.BusinessReader],
            entities: 'INV',
        },
    },
    {
        label: 'Entites.CreativeStatistics',
        key: 'sta',
        icon: <IconStatistics />,
        to: '/creative-statistics',
        roles: {
            roles: [UserRoles.BusinessUser, UserRoles.BusinessReader],
            entities: 'STA',
        },
    },
    {
        label: 'Entites.MassLoad',
        key: 'batch',
        icon: <IconDataExport />,
        className: 'mass-load',
        to: '/batch',
        roles: {
            roles: [UserRoles.BusinessUser, UserRoles.BusinessReader, UserRoles.ReportReader],
        },
    },
    {
        label: 'Заявки на удаление',
        key: 'delete',
        icon: <IconTrash />,
        className: 'delete-entitie',
        to: '/delete-request',
        roles: {
            roles: [UserRoles.BusinessUser],
        },
    },
    {
        label: 'Entites.ServiceLog',
        key: 'rep',
        icon: <ReportsLogo />,
        to: '/servicelog',
        roles: {
            roles: [UserRoles.BusinessUser, UserRoles.BusinessReader, UserRoles.ReportReader],
        },
    },
    {
        label: 'Entites.Keys',
        key: 'key',
        icon: <KeysLogo />,
        to: '/private',
        roles: {
            roles: [UserRoles.BusinessUser],
        },
    },
    {
        label: 'Entites.UserManaging',
        key: 'users',
        icon: <ORGLogo />,
        to: '/account',
        roles: {
            roles: [UserRoles.ORDAdmin, UserRoles.ClientAdmin, UserRoles.NamespaceAdmin],
        },
    },
];
