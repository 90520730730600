import { useAppSelector } from 'app/providers/StoreProvider';
import { TRoleRequiredType } from 'entities/UserAdmin';
import { transformPermissionData } from 'entities/UserAdmin/model/lib';
import React, { FC, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import AppLoad from 'shared/ui/AppLoad/AppLoad';

interface IPermissionRequiredRouteProps {
    requiredRoles: TRoleRequiredType;
}

const PermissionRequiredRoute: FC<IPermissionRequiredRouteProps> = ({ requiredRoles }) => {
    const userPermData = useAppSelector((selector) => selector.userSlice.role);
    const navigate = useNavigate();
    useEffect(() => {
        if (userPermData) {
            const { roles } = transformPermissionData(userPermData);
            const filteredRoleArray = requiredRoles.roles.filter((requiredRole) => roles.includes(requiredRole));
            if (filteredRoleArray.length === 1) {
                const userRoleEntities = userPermData.find((data) => data.role === filteredRoleArray[0])?.permission;
                if (userRoleEntities && requiredRoles.entities) {
                    if (!userRoleEntities.includes(requiredRoles.entities)) {
                        navigate('/forbidden-page');
                    }
                }
            }
        }
    }, [navigate, requiredRoles, userPermData]);
    if (!userPermData) {
        return <AppLoad />;
    }
    if (userPermData) {
        return <Outlet />;
    }
    return null;
};

export default PermissionRequiredRoute;
