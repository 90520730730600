import { TTableSettingsListItemProps } from '@adtech/ui';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPlatformSlice } from './types';

export const initialState: IPlatformSlice = {
    columns: undefined,
    choosedCols: undefined,
    defualtItems: [],
    // batchConfigLabelMap,
};

export const platformSlice = createSlice({
    name: 'platformSlice',
    initialState,
    reducers: {
        setColumns: (state, action) => {
            state.columns = action.payload;
            state.choosedCols = action.payload;
        },
        setDefaultItems: (state, action: PayloadAction<TTableSettingsListItemProps[]>) => {
            state.defualtItems = action.payload;
        },
        resetDefaultItems: (state) => {
            state.defualtItems = state.defualtItems.map((item) => {
                if (item.checked === true && item.disabled !== true) {
                    return { ...item, checked: false };
                }
                return item;
            });
        },
        setChoosedCols: (state, action: PayloadAction<TTableSettingsListItemProps[]>) => {
            const colsToSet = action.payload.filter((item) => item.checked === true);
            const selectedCols: any = [];
            colsToSet.forEach((item) => {
                selectedCols.push(state.columns?.find((col) => col.key === item.id));
            });
            state.choosedCols = selectedCols;
        },
    },
});

export const { setColumns, setChoosedCols, setDefaultItems } = platformSlice.actions;
