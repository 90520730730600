import { Select } from '@adtech/ui';
import { Form } from 'antd';
import React, { FC, useEffect } from 'react';
import { Causes } from '../../model/types';

interface ICauseSelectProps {
    onChange: (value: string | null) => void;
    value: string | null;
}

const CauseSelect: FC<ICauseSelectProps> = (props) => {
    const { onChange, value } = props;
    const option = [
        {
            label: Causes.WRONG_DATA,
            value: Causes.WRONG_DATA,
        },
        {
            label: Causes.DUPLICATE,
            value: Causes.DUPLICATE,
        },
        {
            label: Causes.OTHER,
            value: Causes.OTHER,
        },
    ];
    useEffect(() => {
        return () => {
            onChange(null);
        };
    }, []);
    return (
        <Form.Item label="Укажите причину удаления объекта">
            <Select options={option} onChange={onChange} value={value} placeholder="Выберите причину удаления" />
        </Form.Item>
    );
};

export default CauseSelect;
