import { TTableSettingsListItemProps } from '@adtech/ui';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { IORGSlice, IOrganizationPlatform, batchConfigLabelMap } from './types';

export const initialState: IORGSlice = {
    columns: undefined,
    choosedCols: undefined,
    countriesMap: undefined,
    defualtItems: [],
    platforms: [],
    batchConfigLabelMap,
};

export const organizationsSlice = createSlice({
    name: 'organizationsSlice',
    initialState,
    reducers: {
        setCountries: (state, action: PayloadAction<{ [key: string]: string }>) => {
            state.countriesMap = action.payload;
        },
        setColumns: (state, action) => {
            state.columns = action.payload;
            state.choosedCols = action.payload;
        },
        setDefaultItems: (state, action: PayloadAction<TTableSettingsListItemProps[]>) => {
            state.defualtItems = action.payload;
        },
        resetDefaultItems: (state) => {
            state.defualtItems = state.defualtItems.map((item) => {
                if (item.checked === true && item.disabled !== true) {
                    return { ...item, checked: false };
                }
                return item;
            });
        },
        setChoosedCols: (state, action: PayloadAction<TTableSettingsListItemProps[]>) => {
            const colsToSet = action.payload.filter((item) => item.checked === true);
            const selectedCols: any = [];
            colsToSet.forEach((item) => {
                selectedCols.push(state.columns?.find((col) => col.key === item.id));
            });
            state.choosedCols = selectedCols;
        },
        setPlatforms: (state, action: PayloadAction<IOrganizationPlatform[]>) => {
            state.platforms = action.payload;
        },
        addPlatforms: (state, action: PayloadAction<IOrganizationPlatform>) => {
            state.platforms?.push(action.payload);
        },
        editPlatform: (state, action: PayloadAction<IOrganizationPlatform>) => {
            const targetPlatform = state.platforms.findIndex((platform) => {
                if (action.payload.tempId) {
                    return platform.tempId === action.payload.tempId;
                }
                return platform.platformId === action.payload.platformId;
            });
            if (targetPlatform !== -1) {
                state.platforms[targetPlatform] = action.payload;
            }
        },
        deleteOrganization: (state, action: PayloadAction<string>) => {
            state.platforms = state.platforms?.filter((platform) => {
                if (platform.tempId) {
                    return platform.tempId !== action.payload;
                }
                return platform.platformId !== action.payload;
            });
        },
    },
});

export const {
    setCountries,
    setColumns,
    setChoosedCols,
    setDefaultItems,
    resetDefaultItems,
    setPlatforms,
    addPlatforms,
    deleteOrganization,
    editPlatform,
} = organizationsSlice.actions;
