import { lazy } from 'react';
import {
    contractsApi,
    useGetContractByIdQuery,
    useGetContractsListMutation,
    useCreateContractsMutation,
    useEditContractsMutation,
    useCreateCidMutation,
} from './model/contractsApi';
import {
    setColumns,
    setDefaultItems,
    resetDefaultItems,
    setChoosedCols,
    contractSlice,
    setCobrendMainData,
    addCobrendMember,
    cacheClient,
    setClientLoadState,
    deleteCobrendMember,
    clearCobrendData,
} from './model/contractsSlice';
import type { IContractEntity, IContractsSlice, IContractSearchParams, IContractsListArgs } from './model/types';

const ContractsRouter = lazy(() => import('./routes/ContractsRoute'));

export {
    ContractsRouter,
    setColumns,
    setDefaultItems,
    resetDefaultItems,
    setChoosedCols,
    contractSlice,
    setCobrendMainData,
    addCobrendMember,
    cacheClient,
    setClientLoadState,
    deleteCobrendMember,
    clearCobrendData,
    IContractsSlice,
    IContractEntity,
    IContractSearchParams,
    IContractsListArgs,
    contractsApi,
    useGetContractByIdQuery,
    useGetContractsListMutation,
    useCreateContractsMutation,
    useEditContractsMutation,
    useCreateCidMutation,
};
