import { notification } from 'antd';
import { useAppSelector } from 'app/providers/StoreProvider';
import React, { useEffect } from 'react';

const useBanner = () => {
    const showBanner = localStorage.getItem('showBanner');
    const isAuth = useAppSelector((selector) => selector.appSlice.isAuth);
    if (!showBanner) {
        localStorage.setItem('showBanner', 'true');
    }
    useEffect(() => {
        if (showBanner === 'true' && isAuth) {
            notification.info({
                message: (
                    <div>
                        С 01.01.2024 изменен тарифный план ОРД от SberADS. Ознакомиться с новой тарифной сеткой можно{' '}
                        <a target="_blank" href="https://ord-lab.ru/pricing" rel="noreferrer">
                            здесь
                        </a>
                    </div>
                ),
                placement: 'top',
                duration: 0,
                maxCount: 1,
                onClose: () => {
                    localStorage.setItem('showBanner', 'false');
                },
            });
        }
    }, [isAuth]);
};

export default useBanner;
