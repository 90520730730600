// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tableTool-btn {
  margin-right: 7px;
}
`, "",{"version":3,"sources":["webpack://./src/shared/ui/TableTools/TableTool.less"],"names":[],"mappings":"AAAA;EACI,iBAAA;AACJ","sourcesContent":[".tableTool-btn {\n    margin-right: 7px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
