import { Result } from 'antd';
import React, { FC } from 'react';

const ForbiddenPage: FC = () => {
    return (
        <Result
            status="403"
            title="Нет доступа"
            subTitle="У вас недостаточно прав для доступа на эту страницу, обратитесь к администратору ОРД вашей компании или в техническую поддержку ОРД"
        />
    );
};

export default ForbiddenPage;
