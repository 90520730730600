import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { IUserPerm } from 'entities/UserAdmin/model/types';
import { IConfigResponse } from './types';

export const api = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({ baseUrl: '/' }),
    endpoints: (builder) => ({
        config: builder.query<IConfigResponse, void>({
            query: () => ({
                url: 'public/v1/config',
                method: 'GET',
                validateStatus: (response) => response.status === 200 || response.status === 302,
            }),
        }),
        heartbeat: builder.query<{ next_heartbeat_delay: number }, any>({
            query: () => ({
                url: 'lk/v1/heartbeat',
                method: 'GET',
            }),
        }),
        logout: builder.mutation<void, void>({
            query: () => ({
                method: 'POST',
                url: '/public/v1/logout',
            }),
        }),
        udata: builder.query<
            { permissions: IUserPerm[]; userCompliance: any; isKeycloak: boolean; telegramInviteLink: string },
            any
        >({
            query: () => ({
                url: 'lk/v1/profile',
                method: 'GET',
            }),
        }),
    }),
});

export const { useConfigQuery, useUdataQuery, useLogoutMutation, useHeartbeatQuery } = api;
