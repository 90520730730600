import { TTableSettingsListItemProps } from '@adtech/ui';
import { ColumnsType } from 'antd/lib/table';
import React, { ReactNode } from 'react';
import { IEntityInfo, IStatus } from 'shared/lib/types';

export interface ICreativeData {
    description?: string | null;
    externalId?: string | null;
    hash?: string | null;
    mediaUrl?: string | null;
    media?: {
        downloadUrl: string;
        name: string;
        previewUrl: string;
    };
    textData?: string | null;
    brand?: string | null;
    product?: string | null;
    fileId?: string | null;
}

export interface ITargetRegion {
    code: string;
    name: string;
}

export interface ICreative {
    id?: string | null;
    type?: string | null;
    form?: string | null;
    selfPromotionOrganizationId?: string | null;
    contractId?: string[] | null;
    cid?: string[] | null;
    creativeOkveds?: string[] | null;
    ktu?: string[] | null;
    description?: string | null;
    isNative?: boolean | null;
    isSocial?: boolean | null;
    url?: string[] | null;
    creativeData?: ICreativeData[] | null;
    erirIdType?: string | null;
    // brand?: string | null;
    // product?: string | null;
    targetAudience: {
        age: string[];
        sex: string[];
        geo: ITargetRegion[];
    };
}

export interface ICreativeSearchArgs {
    id?: string[] | string | null;
    limit?: number;
    page?: number;
    status?: Array<string> | string | null;
    contractId?: Array<string> | string | null;
    erirRegDate?: string | null;
    created?: string | null;
    type?: Array<string> | string | null;
    form?: Array<string> | string | null;
    sorting?: Array<string> | null;
    marker?: string;
    selfPromotionOrganizationId?: string | null;
    adsStatus?: string | null;
}

export interface ICreativeEntity {
    creative?: ICreative;
    creativePublication?: boolean;
    contractNumber?: { [key: string]: string };
    organizationName?: string | null;
    entity?: IEntityInfo;
    marker?: string | null;
}

export interface ICreativeBrowseResponse {
    data?: ICreativeEntity[];
    meta: {
        limit: number;
        page: number;
        total: number;
    };
}

export interface IGetCreativeByIdResponse {
    data?: ICreativeEntity;
}

export interface IPostCreativeArgs {
    body: FormData;
}

export interface IPostCreativeResponse {
    body: FormData;
}

export interface IPutCreativeArgs {
    id?: string;
    body: FormData;
}

export interface ICreativesCreateEditResponse {
    data?: IStatus;
}

export type TCreativeBatchConfig = Record<
    keyof Omit<ICreative, 'brand' | 'product' | 'targetGeo' | 'creativeData' | 'id'> &
        Omit<ICreativeData, 'brand' | 'product' | 'hash' | 'media' | 'fileId'>,
    { label: string; tooltip: ReactNode }
>;

export interface ICreativeSlice {
    fileCounter: number;
    files: {
        id: string;
        file?: File;
    }[];
    creativeData: ICreativeData[];
    targetUrls?: Array<string>;
    columns?: ColumnsType<ICreativeEntity>;
    defualtItems: TTableSettingsListItemProps[];
    choosedCols?: ColumnsType<ICreativeEntity>;
    batchConfigLabelMap: TCreativeBatchConfig;
}

export enum EridType {
    LONG = 'LONG',
    MEDIUN = 'MED',
    SHORT = 'SHORT',
    PREM = 'PREM',
}

export interface IKKTUSearch {
    data: {
        id: string;
        name: string;
    }[];
}

export const batchConfigLabelMap: TCreativeBatchConfig = {
    erirIdType: {
        label: 'Тип маркера (строковый)',
        tooltip: <div>Подсказка</div>,
    },
    contractId: {
        label: 'Изначальный договор/доп.соглашение (UUID)',
        tooltip: <div>Подсказка</div>,
    },
    cid: {
        label: 'Идентификатор ИД (cid) (UUID)',
        tooltip: <div>Подсказка</div>,
    },
    creativeOkveds: {
        label: 'Коды ОКВЭД для объекта рекламирования (строковый)',
        tooltip: <div>Подсказка</div>,
    },
    description: {
        label: 'Общее описание объекта рекламирования (строковый)',
        tooltip: <div>Подсказка</div>,
    },
    type: {
        label: 'Тип рекламной кампании (строковый)',
        tooltip: <div>Подсказка</div>,
    },
    form: {
        label: 'Форма распространения рекламы (строковый)',
        tooltip: <div>Подсказка</div>,
    },
    url: {
        label: 'Целевая ссылка (URL) (строковый)',
        tooltip: <div>Подсказка</div>,
    },
    isSocial: {
        label: 'Признак социальной рекламы (строковый)',
        tooltip: <div>Подсказка</div>,
    },
    isNative: {
        label: 'Признак нативной рекламы (строковый)',
        tooltip: <div>Подсказка</div>,
    },
    selfPromotionOrganizationId: {
        label: 'ИНН организации, для которой данный креатив является саморекламой (строковый)',
        tooltip: <div>Подсказка</div>,
    },
    externalId: {
        label: 'Пользовательский идентификатор (строковый)',
        tooltip: <div>Подсказка</div>,
    },
    mediaUrl: {
        label: 'Ссылка на креатив (строковый)',
        tooltip: <div>Подсказка</div>,
    },
    textData: {
        label: 'Текстовое содержимое креатива (строковый)',
        tooltip: <div>Подсказка</div>,
    },
};
