export enum EStatisticsTypes {
    SERVICELOG = 'ServiceLog',
    REPORTEVENTS = 'ReportEvents',
    REPORTSCOUNT = 'ReportsCount',
}

export enum EEntityTypes {
    ORG = 'ORG',
    PLA = 'PLA',
    CON = 'CON',
    CRE = 'CRE',
    INV = 'INV',
    STA = 'STA',
}

export type TPeriodType = 'pastDay' | 'currentMonth' | 'pastMonth';

export interface IEventsReportObjectTypePeriod {
    type: TPeriodType;
    successCount: number;
    errorCount: number;
}

export type TTransormedPeriods = {
    [key in TPeriodType]: {
        successCount: number;
        errorCount: number;
    };
};

export interface IEventsReportObjectType {
    name: EEntityTypes;
    periods: IEventsReportObjectTypePeriod[];
}

export interface IEventsReportResponseTransformed {
    name: EEntityTypes;
    periods: TTransormedPeriods;
}

export interface IEventsReport {
    types: IEventsReportObjectType[];
}

export interface IEventsReportResponse {
    report: IEventsReport;
}

export interface ICountReportType {
    name: EEntityTypes;
    approvedCount: number;
    pendingCount: number;
    declinedCount: number;
}

export interface ICountreport {
    types: ICountReportType[];
}

export interface ICountreportResponse {
    report: ICountreport;
}
