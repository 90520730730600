import { TablePaginationConfig } from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import { useState } from 'react';

export interface IState<EntityType> {
    pagination: TablePaginationConfig;
    sorter: SorterResult<EntityType> | SorterResult<EntityType[]>;
}

const useTableConfig = <EntityType>() => {
    const props = useState<IState<EntityType>>({
        sorter: {},
        pagination: {
            pageSize: 20,
            current: 1,
            total: 100,
            showSizeChanger: true,
            pageSizeOptions: [20, 40, 80],
        },
    });

    return props;
};

export default useTableConfig;
