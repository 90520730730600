// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pickers {
  display: flex;
  align-items: center;
  gap: 8px;
}
.date-buttons {
  display: flex;
  padding: 0px 8px;
  align-items: center;
  gap: 8px;
  list-style: none;
  margin-top: 8px;
}
.date-buttons li {
  line-height: 24px;
}
.date-buttons li span {
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.3px;
  text-wrap: pretty;
  text-shadow: none;
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  color: var(--color-blue-ads);
  cursor: pointer;
}
.date-buttons li.checked span {
  color: var(--carbon-25, rgba(51, 63, 72, 0.25));
}
.date-buttons li:not(:last-child)::after {
  content: '|';
  margin-right: 8px;
  margin-left: 8px;
  color: var(--color-blue-carbon-rgb-25-opacity);
}
`, "",{"version":3,"sources":["webpack://./src/pages/MainPage/ui/NotificationCenter/DateFilters/RangeSelect.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,QAAA;AACJ;AAEA;EACI,aAAA;EACA,gBAAA;EACA,mBAAA;EACA,QAAA;EACA,gBAAA;EACA,eAAA;AAAJ;AANA;EAQQ,iBAAA;AACR;AATA;EAUY,gBAAA;EACA,kBAAA;EACA,eAAA;EACA,iBAAA;EACA,sBAAA;EACA,iBAAA;EACA,iBAAA;EACA,SAAA;EACA,UAAA;EACA,YAAA;EACA,gBAAA;EACA,4BAAA;EACA,eAAA;AAEZ;AAAQ;EAEQ,+CAAA;AAChB;AAGY;EACI,YAAA;EACA,iBAAA;EACA,gBAAA;EACA,8CAAA;AADhB","sourcesContent":[".pickers {\n    display: flex;\n    align-items: center;\n    gap: 8px;\n}\n\n.date-buttons {\n    display: flex;\n    padding: 0px 8px;\n    align-items: center;\n    gap: 8px;\n    list-style: none;\n    margin-top: 8px;\n    li {\n        line-height: 24px;\n        span {\n            font-weight: 400;\n            font-style: normal;\n            font-size: 16px;\n            line-height: 24px;\n            letter-spacing: -0.3px;\n            text-wrap: pretty;\n            text-shadow: none;\n            margin: 0;\n            padding: 0;\n            border: none;\n            background: none;\n            color: var(--color-blue-ads);\n            cursor: pointer;\n        }\n        &.checked {\n            span {\n                color: var(--carbon-25, rgba(51, 63, 72, 0.25));\n            }\n        }\n        &:not(:last-child) {\n            &::after {\n                content: '|';\n                margin-right: 8px;\n                margin-left: 8px;\n                color: var(--color-blue-carbon-rgb-25-opacity);\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
